import { Layer } from 'react-konva'
import { memo } from 'react'
import { AnimatedPoint } from './Point'
import { PointType } from '@/api/services/layer.service'
import { useMapStore } from '@/stores/mapStore'
import { MetablockNodes } from '@/api/services/project.service'
import { LayerBookingItem } from '@/api/services/booking.service'

interface PointsLayerProps {
	options?: {
		labelSize: number
		fontSize: number
		color: string
		borderWidth: number
		wrapText: boolean
	}
	points?: PointType[]
	nodes?: MetablockNodes
	bookings?: LayerBookingItem[]
	url: string
	sourceType: 'use-credentials' | 'anonymous'
	nodeImageSettings: any
	nodesMeta?: any[]
}

const defaultOptions = {
	labelSize: 0.015,
	fontSize: 12,
	color: '#ffffff',
	borderWidth: 0.1,
	wrapText: false,
}

const PointsLayer: React.FC<PointsLayerProps> = ({
	nodes = {},
	options = defaultOptions,
	bookings = [],
	points = [],
	url,
	sourceType,
	nodesMeta = [],
	nodeImageSettings
}) => {
	const [width, height] = useMapStore((state) => state.size)

	if (width == 0 || height == 0) return null

	return (
		<Layer>
			{points.map((point) => (
				<AnimatedPoint
					key={point.id}
					id={point.id}
					point={point}
					options={options}
					nodes={nodes}
					bookings={bookings}
					url={url}
					sourceType={sourceType}
					nodeImageSettings={nodeImageSettings}
					nodesMeta={nodesMeta}
				/>
			))}
		</Layer>
	)
}

export default memo(PointsLayer)

PointsLayer.whyDidYouRender = true
