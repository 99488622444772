import { useMetadata } from '@/api/hooks/useMetadata'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'
import { IReport } from '@/api/services/report.service'
import Close from '@/components/Close'
import Toolbar from '@/components/Toolbar/Toolbar'
import SearchIcon from '@/components/icons/SearchIcon'
import ReportDateSelector from '@/components/layout/Sidebar/Reports/ReportDateSelector'
import { useBookingsReport } from '@/components/layout/Sidebar/Reports/reports/bookings-report/use-bookings-report'
import FormLoader from '@/components/ui/form/FormLoader'
import { useSettingsSelector } from '@/hooks/use-settings-selector'
import { translate } from '@/i18n'
import { useProjectStore } from '@/stores/projectStore'
import { Input } from '@/ui/components/Field/Input'
import Pagination from '@/ui/components/Pagination/Pagination'
import media from '@/ui/media'
import { printPDF } from '@/utils/func/print'
import { formatToReport } from '@/utils/helpers/dates.helpers'
import { addMinutes, endOfDay, endOfWeek, format, isAfter, isValid, parse, startOfDay, startOfWeek } from 'date-fns'
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useIntl } from 'react-intl'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

import debounce from 'lodash/debounce'
import DoubleScrollbar from 'react-double-scrollbar'

type ReportModalProps = {
	open: boolean
	report: IReport
	close: () => void
}

function filterItems(items, filter) {
	return items.filter((item) => {
		return Object.entries(filter).every(([key, value]) => {
			if (value !== '') {
				return String(item[key])
					.replace('\n', ' ')
					.toLowerCase()
					.includes(String(value).toLowerCase())
			}
			return true
		})
	})
}

const BookingsReport: React.FC<ReportModalProps> = ({ close }) => {
	const [currentPage, setCurrentPage] = useState<number>(1)

	const nodes = useProjectStore((state) => state.nodes)
	const [parent, setParent] = useState<string>('')
	const [filters, setFilters] = useState<Record<string, string>>({})
	const [toggle, setOpen] = useState<boolean>(false)
	const [selection, setSelection] = useState<{
		startDate: any
		endDate: any
		key: string
	}>({
		startDate: startOfWeek(new Date()),
		endDate: endOfWeek(addMinutes(new Date(), -30)),
		key: 'selection',
	})
	const [inputValue, setInputValue] = useState(`${format(
		startOfWeek(new Date()),
		'dd.MM.yyyy',
	)} - ${format(endOfWeek(addMinutes(new Date(), -30)), 'dd.MM.yyyy')}`);

	const intl = useIntl()
	const { data: extra } = useMetadata()

	// const handleParentChange = (e) => setParent(e?.target?.value)

	const {
		reportData: bReportData,
		nodesTotal = 0,
		nodesBooked = 0,
		nodesFree = 0,
		isLoading,
		users,
	} = useBookingsReport({
		selection,
		parent,
	})

	useEffect(() => {
		setCurrentPage(1)
	}, [filters])

	const fields = useMemo(() => {
		try {
			const nodes = extra?.nodes || []
			const fields = nodes
				.map((node) => {
					const fieldsObj = Object.values(node.plugin_data).find((item) =>
						(item as Record<string, any>).hasOwnProperty('fields'),
					)
					return fieldsObj ? fieldsObj['fields'] : null
				})
				.reduce((acc, val) => (val ? [...acc, ...val] : acc), [])

			return fields
		} catch (e) {
			return []
		}
	}, [extra?.nodes])

	const { data: userFields } = useUserCardFields()
	let maxStructLength = useRef(0)

	const fieldsMappers =
		userFields?.fields.reduce((acc, val) => {
			acc[val.alias] = val.uid
			return acc
		}, {} as any) || {}

	const cols = useSettingsSelector((settings) => settings.bookingsCols, [])
	const baseColumns = ['parent', 'name', 'polygon', 'type_name']
	const colFields = fields.filter((field) => {
		const names: string[] = []
		cols.forEach((col) => {
			names.push(col.name)
			if (col.alias) {
				names.push(...col.alias)
			}
		})

		return names
			.filter((v) => v)
			.some((v) => v.includes(field.name) || v.includes(field.alias))
	})


	const reportData = bReportData || []
	const reportDataItems =
		reportData.map((item, idx) => {
			let newItem = {
				...item,
				num: idx + 1,
				id: item.id,
				name: item.name,
				parent: item.parent,
				polygon: item.polygon,
				parentId: item.parent,
				type_name: item.type_name,
				booking_type: item.booking
					? item.booking.rec != '0'
						? 'Еженедельная'
						: item.booking.end
							? 'Обычная'
							: 'Постоянная'
					: null,
			}

			cols.forEach((v) => {
				const prop =
					item.booking?.[fieldsMappers?.[v.key]] || item.booking?.[v.key]
				// newItem[v.key] = prop
				if (v.key === 'start') {
					newItem[v.key] = prop ? format(new Date(prop), 'dd.MM.yyyy') : ''
					return
				}
				if (v.key === 'booking_type') {
					newItem[v.key] = prop
						? item.booking.rec != '0'
							? 'Еженедельная'
							: item.booking.end
								? 'Обычная'
								: 'Постоянная'
						: null
					return
				}
				if (v.key === 'booking_status') {
					newItem[v.key] = item.booking ? 'Занято' : 'Свободно'
					return
				}
				if (v.key === 'manager') {
					newItem[v.key] = item.isManager ? 'Да' : 'Нет'
					return
				}
				if (v.key === 'photo') {
					newItem[v.key] = item.booking ? (item.photo ? 'Есть' : 'Нет') : ''
					return
				}
				if (v.key === 'protected') {
					newItem[v.key] =
						item.protected == null ? '' : item.protected == '1' ? 'Да' : 'Нет'
					return
				}
				if (v.key.includes('extensionattribute15')) {
					const extprop =
						item.booking?.[fieldsMappers?.['extensionattribute15']] ||
						item.booking?.['extensionattribute15']
					const struct = extprop ? extprop.split('>').reverse().map((n) => n.trim()) : []
					struct.forEach((s, idx) => {
						const index = idx + 1
						newItem[`extensionattribute15_${index}`] = struct.at(idx) ?? ''
						if (maxStructLength.current < index) {
							maxStructLength.current = index
						}
					})
					return
				}
				newItem[v.key] = prop
			})

			const nodeType = nodes.find((node) => node.id == item.parent)
			newItem['parent'] = nodeType?.name || item.parent

			colFields.forEach((field) => {
				const key = cols.find(
					(c) =>
						c.name == field.name ||
						c.alias == field.name ||
						c.alias == field.extension ||
						c.alias?.includes(field.name) ||
						c.alias?.includes(field.extension),
				)?.key
				if (!key) return

				const props = Object.keys(item)
				const prop = props.find((p) => p.endsWith('_' + field.id))

				if (prop && !newItem[key]) {
					if (key == 'node_status') {
						if (field.extension == 'status') {
							newItem[key] = item[prop]
							return
						} else if (item[prop] == 1) {
							newItem[key] = 'Ремонт'
							return
						} else {
							newItem[key] = ''
							return
						}
					}
					newItem[key] = item[prop]
				}
			})

			return newItem
		}) || []

	const reportItems = filterItems(reportDataItems, filters)

	const extendedColumns = useMemo(() => cols.filter(v => v.key == 'extensionattribute15_1' || !v.key.includes('extensionattribute15')).reduce((acc, col) => {
		if (col.key.includes('extensionattribute15')) {
			const newAcc = []
			for (let i = 1; i <= maxStructLength.current; i++) {
				acc.push({
					key: 'extensionattribute15_' + i,
					name: 'Оргструктура ур.' + i
				})
			}

			return [...acc, ...newAcc]
		}
		return [...acc, col]
	}, []), [maxStructLength.current])

	const columns = [...baseColumns, ...extendedColumns.map((col) => col.key)] || []
	const colHeaders = [
		{ label: intl.formatMessage({ id: 'report-parent' }), key: 'parent' },
		{
			label: intl.formatMessage({ id: 'create-booking-report-name' }),
			key: 'name',
		},
		{
			label: 'Полигон',
			key: 'polygon',
		},
		{ label: intl.formatMessage({ id: 'report-type_name' }), key: 'type_name' },
		...(extendedColumns || []).map((field) => ({
			label: field.name,
			key: field.key,
		})),
	]

	const pdfCols = useMemo(
		() => colHeaders.map((col) => ({ header: col.label, dataKey: col.key })),
		[colHeaders],
	)


	const colTranslations = extendedColumns.reduce(
		(acc, val) => ({
			...acc,
			[val.key]: val.name,
		}),
		{},
	)

	const translations = {
		type_name: intl.formatMessage({ id: 'report-type_name' }),
		name: intl.formatMessage({ id: 'create-booking-report-name' }),
		polygon: 'Полигон',
		parent: intl.formatMessage({ id: 'report-parent' }),
		...colTranslations,
	}


	const additionalInfo = useMemo(
		() => [
			`Всего мест: ${nodesTotal}`,
			`Из них занято : ${nodesBooked}`,
			`Свободных мест : ${nodesFree}`,
			'',
			`всего сотрудников: ${users.total}`,
			`из них с постоянной бронью: ${users.constant}`,
			`из них с еженедельной бронью: ${users.recurrent}`,
			`из них с обычной бронью: ${users.regular}`,
		],
		[users, nodesTotal, nodesBooked, nodesFree],
	)

	const handleFilters = (
		column: string,
		event: ChangeEvent<HTMLInputElement>,
	) => {
		setFilters({
			...filters,
			[column]: event.target.value,
		})
	}


	const handleInput = (sel) => {
		setInputValue(`${format(
			sel.startDate,
			'dd.MM.yyyy',
		)} - ${format(sel.endDate, 'dd.MM.yyyy')}`)
	}
	const handleSelection = (sel) => {
		handleInput(sel)
		setSelection(sel)
	}

	const extractDate = (date: string | null | undefined) => {
		if (!date) return null
		const formatted = parse(date, 'dd.MM.yyyy', new Date())
		if (!isValid(formatted)) return null
		return formatted
	}

	const handleInputSelection = (event) => {
		const value = event.target.value
		const [start, end] = value.split('-')
		const startDate = extractDate(start?.trim())
		const endDate = extractDate(end?.trim())
		if (!startDate || !endDate || isAfter(startDate, endDate)) return

		const newSelection = { startDate: startOfDay(startDate), endDate: endOfDay(endDate), key: 'selection' }
		setSelection(newSelection)
	}

	const handleDateChange = (event) => {
		setInputValue(event.target.value);
		debouncedSelectionResponse(event)
	}

	const debouncedSelectionResponse = useMemo(() => {
		return debounce(handleInputSelection, 200)
	}, [])

	useEffect(() => {
		return () => debouncedSelectionResponse.cancel()
	}, [])

	return (
		<ModalWrapper>
			<Header>
				<Title>Цифровой двойник офиса</Title>
				<ExportWrapper>
					<ReportButton
						onClick={() =>
							printPDF({
								name: `Цифровой двойник офиса ${formatToReport(
									selection.startDate,
								)} - ${formatToReport(selection.endDate)}`,
								columns: pdfCols,
								body: reportItems,
								orientation: 'l',
								size: 6,
								additional: additionalInfo,
							})
						}
					>
						PDF
					</ReportButton>
					<CSVLink
						data={[
							...reportItems,
							{},
							{},
							{},
							{ parent: 'Всего мест:', name: nodesTotal },
							{ parent: 'Из них занято:', name: nodesBooked },
							{ parent: 'Свободных мест:', name: nodesFree },
							{},
							{ parent: 'всего сотрудников:', name: users.total },
							{ parent: 'из них с постоянной бронью:', name: users.constant },
							{
								parent: 'из них с еженедельной бронью:',
								name: users.recurrent,
							},
							{ parent: 'из них с обычной бронью:', name: users.regular },
						]}
						headers={colHeaders}
						separator={';'}
						filename={`Цифровой двойник офиса ${formatToReport(
							selection.startDate,
						)} - ${formatToReport(selection.endDate)}`}
					>
						<ReportButton>CSV</ReportButton>
					</CSVLink>
					<Close color="#000" onClick={close} />
				</ExportWrapper>
			</Header>

			<Toolbar>
				<Toolbar.Item xs={6} md={4}>
					<Toolbar.BlackLabel>Выберите интервал</Toolbar.BlackLabel>
					<Input
						$fullWidth
						value={inputValue}
						onClick={() => setOpen(true)}
						onChange={handleDateChange}
						onBlur={() => handleInput(selection)}
					/>
					<ReportDateSelector
						open={toggle}
						setOpen={setOpen}
						selection={selection}
						setSelection={handleSelection}
					/>
				</Toolbar.Item>
				{/*<Toolbar.Item xs={6} md={4}>*/}
				{/*	<Toolbar.BlackLabel>Местоположение</Toolbar.BlackLabel>*/}
				{/*	<SelectInput $fullWidth value={parent} onChange={handleParentChange}>*/}
				{/*		<option value="">{translate('all-levels')}</option>*/}
				{/*		{nodes.map((node) => (*/}
				{/*			<option key={node.id} value={node.id}>*/}
				{/*				{node.name}*/}
				{/*			</option>*/}
				{/*		))}*/}
				{/*	</SelectInput>*/}
				{/*</Toolbar.Item>*/}
			</Toolbar>

			<ReportMeta>
				<div>
					Всего мест: <b>{nodesTotal}</b>
				</div>
				<div>
					Из них занято: <b>{nodesBooked}</b>
				</div>
				<div>
					Свободных мест: <b>{nodesFree}</b>
				</div>
				<br />
				<div>
					всего сотрудников: <b>{users.total}</b>
				</div>
				<div>
					из них с постоянной бронью: <b>{users.constant}</b>
				</div>
				<div>
					из них с еженедельной бронью: <b>{users.recurrent}</b>
				</div>
				<div>
					из них с обычной бронью: <b>{users.regular}</b>
				</div>
			</ReportMeta>

			<div style={{ width: '100%' }}>
				<DoubleScrollbar>
					<Table>
						<thead>
							<tr>
								{columns.map((col) => (
									<th key={col}>{translations[col]}</th>
								))}
							</tr>
							<tr>
								{columns.map((col) => (
									<th className="search-row" key={col}>
										<div className="search-box">
											<SearchInput
												placeholder="Поиск"
												onChange={(event) => handleFilters(col, event)}
											/>
											<SearchIcon />
										</div>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{isLoading ? (
								<tr>
									<td colSpan={columns.length + 1}>
										<FormLoader isLoading={true} />
									</td>
								</tr>
							) : reportItems.length ? (
								reportItems
									.slice((currentPage - 1) * 20, currentPage * 20)
									.map((spot) => (
										<ReportRow key={spot.key} columns={columns} item={spot} />
									))
							) : (
								<tr>
									<td colSpan={columns.length + 1}>
										<NotFound>{translate('no-results')}</NotFound>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</DoubleScrollbar>
			</div>

			<Pagination
				inverse
				currentPage={currentPage}
				total={reportItems.length || 0}
				handlePageChange={setCurrentPage}
			/>

			<Tooltip id="create-booking-report-tooltip" />
		</ModalWrapper>
	)
}

export default BookingsReport

const ReportMeta = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	font-size: 12px;
	margin: 8px 0 16px 0;
`

const SearchInput = styled.input`
	width: 100%;
	border: none;
	padding: 8px 12px;
	outline: none;
	font-size: 14px;
`

const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	margin: 16px 0;

	/* table-layout: fixed; */

	th:first-child,
	td:first-child {
		width: 1%;
		white-space: nowrap;
	}

	//th:last-child,
	//td:last-child {
	//	width: 90px;
	//}

	th,
	td {
		padding: 8px 16px;
		border: 1px solid #cccccc;
		border-collapse: collapse;
		vertical-align: middle;
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.search-row {
		padding: 0;
	}

	.search-box {
		display: flex;
		align-items: center;
		padding-right: 8px;
	}

	th {
		color: #000;
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
	}

	td {
		& > div {
			display: flex;
			align-items: center;
		}
	}
`

const ReportRow = ({ item, columns }) => {
	const cols = columns
		.map((column) => {
			let data = item[column]

			return {
				key: column,
				value: data,
			}
		})
		.filter((v) => v)

	return (
		<tr>
			{cols.map(({ key, value }) => (
				<td key={key}>
					<div style={{ display: 'flex', gap: '8px' }}>{value}</div>
				</td>
			))}
		</tr>
	)
}

const ModalWrapper = styled.div<{ $maxWidth?: number }>`
	background: #ffffff;
	max-width: 1900px;
	width: 100%;
	max-height: 100%;
	max-height: var(--app-height);
	padding: 30px 40px;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 20001;
	border-radius: 8px;

	${media.lg`
    padding: 20px;
  `}
	${media.md`
    padding: 10px;
  `}
`

const NotFound = styled.div`
	width: 100%;
	text-align: center;
	padding: 12px 0;
	justify-content: center;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${media.lg`
        flex-direction: column;
        align-items: flex-start;
    `}
`

export const ReportButton = styled.button`
	border: 2px solid #d2233c;
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 1.6rem;
	line-height: 1.6rem;
	color: #d2233c;
	padding: 1.2rem 2rem;
	outline: none;
	background: transparent;
	cursor: pointer;
`

const Title = styled.div`
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: #000000;
`

const ExportWrapper = styled.div`
	display: flex;
	align-items: center;

	* {
		&:not(:last-child) {
			margin-right: 0.8rem;
		}
	}

	${media.lg`
        margin-top: 20px;
    `}
`
