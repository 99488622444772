
import React from 'react'

const LukoilLogo = ({ color = '#fff' }) => {
    return (
        <svg width="190" height="36" viewBox="0 0 190 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.4318 35.1644H33.3052V3.22038H1.4318V35.1644ZM33.9718 35.8311H0.765137V2.55371H33.9718V35.8311Z" fill="white"/>
<path d="M30.0405 9.07825L26.3858 14.9876C25.9885 15.6529 26.0445 16.1716 26.2311 16.6769L31.7818 30.8716H8.79781C6.73514 30.8716 5.13914 29.2169 5.13914 27.2449L5.11914 8.34625C5.17514 5.90359 8.73114 5.86359 8.79781 8.33558V26.0503C8.79247 26.6102 9.28447 27.2449 9.93514 27.2289H26.7685L22.4111 16.9716C22.2965 16.5796 22.2205 16.1876 22.2205 15.7969C22.2205 15.3636 22.4111 14.6676 22.6951 14.1023L26.6951 7.51692C26.9898 6.92492 27.5898 6.52758 28.2978 6.49558C29.2951 6.45558 30.1485 7.14092 30.1885 8.19692C30.1951 8.40759 30.1431 8.87292 30.0405 9.07825Z" fill="white"/>
<path d="M17.3634 21.013V8.33833C17.3634 5.865 20.9714 5.845 21.0127 8.33833V21.1517C21.0127 24.0837 18.8221 26.021 16.1394 26.021C13.4567 26.021 11.2607 24.037 11.2607 21.1517V8.33833C11.2607 5.865 14.9087 5.865 14.9087 8.33833V21.013C14.9087 22.7117 17.3634 22.817 17.3634 21.013Z" fill="white"/>
<path d="M61.3893 6.80466V30.702H54.6973V12.5407H49.9187V21.962C49.9187 25.41 49.5773 31.0073 41.2493 31.0073C40.2573 31.0073 39.5067 30.974 38.448 30.702V24.966C38.9613 25.1033 39.4387 25.2047 39.9173 25.2047C42.8533 25.2047 43.228 23.362 43.228 19.982V6.80466H61.3893Z" fill="white"/>
<path d="M71.7343 6.80482L76.4103 16.8408L80.2343 6.80482H87.505L76.7517 30.7021H69.5837L72.8263 24.1115L63.8477 6.80482H71.7343Z" fill="white"/>
<path d="M89.7251 6.80482H96.4171V16.8408L103.62 6.80482H111.472L102.39 18.3781L111.71 30.7021H103.346L96.4171 20.4261V30.7021H89.7251V6.80482Z" fill="white"/>
<path d="M148.121 6.80482V21.1421L157.372 6.80482H163.926V30.7021H157.474V16.3635L148.224 30.7021H141.669V6.80482H148.121ZM159.968 1.68348C158.669 4.48348 155.632 5.50748 152.798 5.50748C149.965 5.50748 146.926 4.48348 145.629 1.68348L149.452 0.250149C150.066 1.88748 151.569 2.26482 152.798 2.26482C154.028 2.26482 155.529 1.88748 156.144 0.250149L159.968 1.68348Z" fill="white"/>
<path d="M189.401 6.80466V30.702H182.709V12.5407H177.929V21.962C177.929 25.41 177.589 31.0073 169.26 31.0073C168.269 31.0073 167.518 30.974 166.461 30.702V24.966C166.972 25.1033 167.449 25.2047 167.929 25.2047C170.864 25.2047 171.238 23.362 171.238 19.982V6.80466H189.401Z" fill="white"/>
<path d="M111.983 18.7588C111.983 12.2028 116.188 5.78816 125.164 5.78816C134.138 5.78816 138.343 12.2028 138.343 18.7588C138.343 25.3135 134.138 31.7295 125.164 31.7295C116.188 31.7295 111.983 25.3135 111.983 18.7588ZM131.264 18.7588C131.264 14.0268 128.352 11.6775 125.164 11.6775C121.972 11.6775 119.066 14.0268 119.066 18.7588C119.066 23.4922 121.972 25.8388 125.164 25.8388C128.352 25.8388 131.264 23.4922 131.264 18.7588Z" fill="white"/>
</svg>
)
}

export default LukoilLogo