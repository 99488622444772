import { BookingTypeEnum } from '@/api/bookings'
import { useBookings } from '@/api/hooks/useBookings'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useNodes } from '@/api/hooks/useNodes'
import { useTree } from '@/api/hooks/useTree'
import { useUsers } from '@/api/hooks/useUsers'
import { useUsersWithPhotos } from '@/api/hooks/useUsersWithPhotos'
import { MetadataNode, ProjectTree } from '@/api/services/project.service'
import { TreeNodeData } from '@/components/Elements/tree'
import { useProjectStore } from '@/stores/projectStore'
import uniqueBy from '@popperjs/core/lib/utils/uniqueBy'
import { useMemo } from 'react'

const getBookingType = (item) => {
	return item.rec == '1'
		? BookingTypeEnum.Recurent
		: item.end
		? BookingTypeEnum.Regular
		: BookingTypeEnum.Constant
}

const extractFloors = (tree: ProjectTree[]) => {
	const floors = tree.reduce((acc, item) => {
		item.childs.forEach((child) => {
			acc.push({ ...child, childs: [] })
		})

		return acc
	}, [] as ProjectTree[])

	return floors.map((floor) => ({
		id: floor.id,
		name: floor.name,
		parent: floor.parent_id,
	}))
}

const extractMetaFields = (meta: MetadataNode[] | undefined) => {
	if (!meta) return []

	try {
		// @ts-ignore
		return meta.map(field => Object.values(field.plugin_data).find(field => field?.fields)?.fields).filter(v => v).flat()
	} catch(e) {
		console.error(e)
		return []
	}
}

const useFloors = () => {
	const { layers } = useTree()

	return useMemo(() => extractFloors(layers?.tree || []), [layers])
}

export const useBookingsReport = ({ selection, parent }) => {
	const nodes = useProjectStore((state) => state.nodes)
	const { data: userIds } = useUsersWithPhotos()
	const { data: users } = useUsers({
		page: 1,
		perPage: 5000,
	})


	const usersMap = useMemo(() => {
		if (!users || !users.items) return new Map()
		let map = new Map()
		
		users.items.forEach(user => {
			map.set(user.id, user)
		})

		return map
	}, [users])

	const { data: meta } = useMetadata()
	const fields = useMemo(() => extractMetaFields(meta?.nodes), [meta?.nodes])
	const managerFields = useMemo(() => fields.filter(f => f.name.includes('#manager')), [fields])
	

	const floors = useFloors()

	const { data, isLoading } = useNodes({
		page: 0,
		perPage: 5000,
		name: '',
		parent: '',
		type: '',
		sort: '',
		direction: 0,
		bookable: 1,
	})

	const { data: bookings, isLoading: isBookingsLoading } = useBookings({
		page: 1,
		perPage: 5000,
		moment: 'all',
		// day,
		start: selection.startDate,
		end: selection.endDate,
		sort: '',
		direction: 0,
	})
	// const props = linkProperties(data?.node_info?.plugin_data, meta?.plugin_data)

	const reportData = data
		? data.items.reduce((acc, val) => {
				const nodeBookings = bookings?.items.filter((b) => b.bookable_id == val.id) || []
				
				const result: any[] = [];

				for (const field of managerFields) {
					const id = `f_f7222e6eb7114fbd865be059646cbc53_${field.id}`;
					if (val.hasOwnProperty(id)) {
						result.push({
							id: field.id,
							name: field.name,
							value: val[id],
							type_id: field.type_id
						});
					}
				}

				let isManager = result.some(field => field.value == true || field.value == 'value' || field.value == '1')
				
				let item = {
					...val,
					...getCurrentLevelInfo(nodes, floors, val.parent),
					isManager,
					key: val.id
					// photo: userIds.some(id => id == val.)
				}


				// console.log(val)

				if (nodeBookings.length) {

					nodeBookings.forEach((b) => {
						const user = usersMap.get(b.user_id)
						const isUserProtected = user ? user['protected'] : null
						acc.push({ ...item, booking: b, key: val.id + '_' + b.id, photo: userIds.some(id => id == b.user_id), protected: isUserProtected })
					})
				} else {
					acc.push({ ...item, booking: null })
				}

				return acc
		  }, [] as any)
		: []

	const nodesTotal = data?.total || 0
	const bookedNodes = useMemo(
		() => uniqueBy(bookings?.items || [], (b) => b.bookable_id).length || 0,
		[bookings],
	)

	const freeNodes = nodesTotal - bookedNodes
	const bookingsByUniqUsers = useMemo(
		() =>
			uniqueBy(bookings?.items || [], (b) => b.user_id).map((b) => ({
				...b,
				type: getBookingType(b),
			})) || [],
		[bookings],
	)
	const usersCount = bookingsByUniqUsers.length
	const regularBookingsCount = useMemo(
		() =>
			bookingsByUniqUsers.filter(
				(booking) => Number(booking.type) === BookingTypeEnum.Regular,
			).length || 0,
		[bookingsByUniqUsers],
	)
	const recurrentBookingsCount = useMemo(
		() =>
			bookingsByUniqUsers.filter(
				(booking) => Number(booking.type) === BookingTypeEnum.Recurent,
			).length || 0,
		[bookingsByUniqUsers],
	)
	const constantBookingsCount = useMemo(
		() =>
			bookingsByUniqUsers.filter(
				(booking) => Number(booking.type) === BookingTypeEnum.Constant,
			).length || 0,
		[bookingsByUniqUsers],
	)

	return {
		bookings,
		users: {
			total: usersCount,
			regular: regularBookingsCount,
			recurrent: recurrentBookingsCount,
			constant: constantBookingsCount,
		},
		nodesTotal,
		nodesBooked: bookedNodes,
		nodesFree: freeNodes >= 0 ? freeNodes : 0,
		data: {
			...data,
			items: data?.items.map((item) => ({
				...item,
				...getLevelInfo(nodes, item.parent, floors),
			})),
		},
		reportData: reportData.sort(sortByParentNodeName),
		isLoading: isLoading || isBookingsLoading,
	}
}

const sortByParentNodeName = function (a, b) {
	if (a.parent < b.parent) {
	  return -1;
	}
	if (a.parent > b.parent) {
	  return 1;
	}
	return 0;
  }

const getParentIds = (layers, childId) => {
	const parentIds: Array<number | null> = []
	const findParentIds = (currentId) => {
		const layer = layers.find((l) => l.id == currentId)

		if (layer) {
			parentIds.push(layer.parent)
			if (layer.parent != undefined) {
				findParentIds(layer.parent)
			}
		}
	}

	findParentIds(childId)

	return parentIds.filter((v) => v)
}

const getCurrentLevelInfo = (
	nodes: TreeNodeData[],
	floors: any[],
	parent: string | number,
) => {
	const parentNode = nodes.find((n) => n.id == parent)
	const isPolygonFloor = floors.some((fl) => fl.id == parent)
	const parentNodeName = isPolygonFloor
		? ''
		: parentNode
		? parentNode?.name
		: ''

	const layerIds = getParentIds(nodes, parent)
	const floorIds = floors.map((f) => f.id)
	const layerFloorIds = layerIds.filter((lId) => floorIds.includes(lId))
	const floorId = layerFloorIds.at(0) || null

	const layerNode = nodes.find((n) => n.id == floorId)
	const layerNodeName = layerNode ? layerNode.name : ''

	return {
		polygon: parentNodeName,
		parent: isPolygonFloor ? parentNode?.name : layerNodeName,
	}
}

const getLevelInfo = (
	nodes: TreeNodeData[],
	parent: string | number,
	floors: any[],
) => {
	const currentNode = nodes.find((n) => n.id == parent)
	const currentNodeName = currentNode ? currentNode?.name : ''
	const parentNodeName = currentNode
		? nodes.find((n) => n.id == Number(currentNode.parent))?.name
		: ''
	const layerName = floors

	return {
		polygon: parentNodeName,
		layer: 12,
		name: currentNodeName,
	}
}
