import IFrameAuth from '@/components/IFrameAuth'
import NotFound from '@/components/NotFound'
import Loader from '@/components/Preloader/Loader'
import ProtectedRoutes from '@/components/ProtectedRoutes'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import useSettings from '@/hooks/useSettings'
import LogoutPage from '@/pages/logout'
import '@/settings.json'
import { Route, Routes } from 'react-router-dom'
import LoginContainer from './LoginContainer'
import ProjectContainer from './ProjectContainer'

const AppContainer = () => {
	const { isLoading } = useSettings()
	const isSingle = useSettingsSelector((settings) => settings.single, false)

	if (isLoading) return <Loader />

	return (
		<Routes>
			{isSingle ? (
				<>
					<Route path="*" element={<NotFound />} />
					<Route path="/" element={<IFrameAuth />}>
						<Route path="/login" element={<LoginContainer />} />
						<Route element={<ProtectedRoutes />}>
							<Route index element={<ProjectContainer />} />
						</Route>
						<Route path="/logout" element={<LogoutPage />} />
					</Route>
				</>
			) : (
				<>
					<Route element={<IFrameAuth />}>
						<Route path="/login" element={<LoginContainer />} />
						<Route element={<ProtectedRoutes />}>
							<Route
								path="/project/:workspaceId/:projectId"
								element={<ProjectContainer />}
							/>
						</Route>
						<Route path="/logout" element={<LogoutPage />} />
						<Route index element={<NotFound />} />
					</Route>
				</>
			)}
		</Routes>
	)
}

export default AppContainer
