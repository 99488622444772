import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import { ReportsService } from '../services/report.service'

export const useReports = () => {
	const { workspaceId } = useProject()
	const { enqueueToast } = useToast()

	const { data, isLoading, refetch } = useQuery(
		['reports', workspaceId],
		() => ReportsService.getReports(workspaceId),
		{
			enabled: !!workspaceId,
			select: ({ data }) => data,
			onError: ({ message }) => {
				enqueueToast({ title: 'Ошибка!', message }, { variant: 'error' })
			},
		},
	)

	return {
		reports: data?.reports || [],
		data,
		isLoading,
		refetch,
	}
}
