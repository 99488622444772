import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import { BookingService } from '../services/booking.service';

type UseNodesProps = {
    page?: number
    perPage?: number
    parent?: string
    name?: string
    type?: string
    categories?: string
    bookable?: 0 | 1
    sort?: string
    direction?: 0 | 1
}

export const useNodes = ({ page, perPage = 20, parent, name, type, bookable, sort, direction, categories }: UseNodesProps) => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()

    const pageId = Number(page)

    const { data, isLoading, refetch, isFetching } = useQuery(
        ['booking_node_list_', workspaceId, projectId, pageId, parent, name, type, bookable, sort, direction, categories],
        () => BookingService.getNodes({
            workspaceId,
            projectId,
            perPage,
            page: pageId,
            name,
            parent,
            type,
            bookable,
            categories,
            sort,
            direction
        }),
        {
            enabled: !!workspaceId && !!projectId && (pageId >= 0),
            keepPreviousData: true,
            select: ({ data }) => ({ items: data.items, total: data.total }),
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить данные об объектах' }, { variant: 'error' })
            }
        }
    )

    return { data, isLoading, refetch, isFetching }
}