import { StatusResponseEnum } from '@/api'
import { useMe } from '@/api/hooks/useMe'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Preloader from '@/components/Preloader/Preloader'
import { ProjectService } from '@/api/services/project.service'
import { useToast } from './shared/toast/useToast'
import { MetadataPluginsEnum } from '@/utils/constants/booking.constants'
import { useProjectStore } from '@/stores/projectStore'
import { useIntl } from 'react-intl'
import { useProject } from '@/hooks/useProject'

function LoadingComponent() {
	return <Preloader show={true} />
}

const extractCategories = (pluginData) => {
	const plugin: any = Object.values(pluginData).find(
		(obj: any) => obj?.categories,
	)
	if (!plugin) return []
	return plugin.categories
}

const useAuth = () => {
	const { user } = useMe()
	const { workspaceId, projectId } = useProject()
	const { enqueueToast } = useToast()
	const intl = useIntl()

	// selectors
	const setUser = useUserStore((state) => state.setUser)
	const setCategories = useUserStore((state) => state.setCategories)
	const setAuthenticated = useUserStore((state) => state.setAuthenticated)
	const setAuthRef = useUserStore((state) => state.setAuthRef)
	const setRole = useUserStore((state) => state.setRole)
	const setFullMode = useProjectStore((state) => state.setFullMode)

	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const fetchData = async () => {
		try {
			const response = await ProjectService.getMetadata({
				workspaceId: Number(workspaceId),
				projectId: Number(projectId),
			})
			if (response && response.data.status === StatusResponseEnum.Success) {
				const categories = extractCategories(
					response.data.project_user_data?.plugin_data,
				)

				const fullMode = response.data.metablock.plugins.includes(
					MetadataPluginsEnum.Booking,
				)
				setFullMode(fullMode)

				setCategories(categories)
				setIsAuthenticated(true)

				const authRef = response.data.authref

				if (authRef) {
					setAuthRef(authRef)
				}

				const isAdmin = response.data.project_access === RolesEnum.Admin
				const isUser = response.data.project_access === RolesEnum.User
				if (isAdmin) {
					setRole(RolesEnum.Admin)
				} else if (isUser) {
					setRole(RolesEnum.User)
				} else {
					setRole(RolesEnum.None)
				}
			}
		} catch (e) {
			enqueueToast(
				{
					title: intl.formatMessage({ id: 'error' }),
					message: intl.formatMessage({ id: 'error-load-metadata' }),
				},
				{ variant: 'error' },
			)
		}

		setIsLoading(false)
	}

	useEffect(() => {
		fetchData()
	}, [])

	useEffect(() => {
		if (!user) return
		setUser(user)

		if (user.role === RolesEnum.Admin) {
			setRole(RolesEnum.Admin)
		}

		setAuthenticated(true)
	}, [user])

	return { isAuthenticated, isLoading }
}

const ProtectedRoutes = () => {
	const { workspaceId, projectId } = useProject()

	const isAuth = useAuth()

	if (isAuth.isLoading) {
		return <LoadingComponent />
	}

	if (!isAuth.isAuthenticated) {
		return (
			<Navigate
				to={`/login?workspace_id=${workspaceId}&project_id=${projectId}`}
			/>
		)
	}

	return <Outlet />
}

export default ProtectedRoutes
