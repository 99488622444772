import InfoIcon from '@/components/icons/InfoIcon'
import ModalService from '@/components/shared/modal/ModalService'
import { translate } from '@/i18n'
import React from 'react'
import styled from 'styled-components'
import EmptySeatsReportModal from './EmptySeats'
import ReportModal from './ReportModal'
import InventoryReportModal from './reports/inventory/InventoryReportModal'
import SquareReportModal from './SquareReports'
import BookingsReport from '@/components/layout/Sidebar/Reports/reports/bookings-report/BookingsReport'
import { useReports } from '@/api/hooks/useReports'

type ReportsItemProps = {
	item: {
		name: string
		key: string
	}
}

const ReportsItems: React.FC<ReportsItemProps> = ({ item }) => {
	const { reports } = useReports()
	const freePlaceReport = reports.find(
		(report) => report.name === 'free-places-v1',
	)

	const handleReportOpen = (key: string) => {
		const props = { report: key, item }

		if (key === 'bookings-report' || key === 'place-report') {
			ModalService.open(ReportModal, props)
		}

		if (key === 'square-report') {
			ModalService.open(SquareReportModal, props)
		}

		if (key === 'inventory-report') {
			ModalService.open(InventoryReportModal, props)
		}

		if (key === 'empty') {
			ModalService.open(EmptySeatsReportModal, props)
		}

		if (key === 'bookings-places-report') {
			ModalService.open(BookingsReport)
		}
	}

	return (
		<>
			<ReportWrapper>
				<ReportName>{translate(item.key)}</ReportName>
				<ReportControls>
					{/* <ReportControlIconButton onClick={() => setReport('parking')}>
                        <ExportIcon />
                    </ReportControlIconButton> */}
					<ReportControlButton onClick={() => handleReportOpen(item.key)}>
						<InfoIcon color="#666" />
					</ReportControlButton>
				</ReportControls>
			</ReportWrapper>
		</>
	)
}

export default ReportsItems

const ReportWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
`

const ReportName = styled.div`
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 1.6rem;
	margin-right: 1.6rem;
	color: #666;
`

const ReportControls = styled.div`
	display: flex;
	align-items: center;
`

const ReportControlButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	background: transparent;
	cursor: pointer;

	&:not(:last-child) {
		margin-right: 0.8rem;
	}
`

const ReportControlIconButton = styled(ReportControlButton)`
	width: 30px;
	height: 30px;
	background: #fff;
	border-radius: 50%;
`
