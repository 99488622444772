import { IUser, UserService } from '../services/user.service'
import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import { isNonNullable } from '@/utils/type-guards/is-non-nullable'

export const useUsersWithPhotos = () => {
	const { workspaceId, projectId } = useProject()
	const { enqueueToast } = useToast()

	return useQuery(
		['users-with-photos', workspaceId, projectId],
		() => UserService.getUserIdsWithPhoto({workspaceId, projectId}),
		{
			enabled: isNonNullable(workspaceId) && isNonNullable(projectId),
			select: ({ data }): any => {
                const items = data ? data['items'] : []
				const list = items ?? []
                return Object.entries(list).map(([key, val]) => val ? Number(key) : null).filter(v => v)
            },
			onError: ({ message }) => {
				enqueueToast({ title: 'Ошибка!', message }, { variant: 'error' })
			},
		},
	)

}
