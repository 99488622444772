import { api, ApiResponseType } from "@/api";

export const ProjectService = {
    async getMetadata({ workspaceId, projectId }: ProjectMetadataDto) {
        return api.get<ProjectMetadataResponse>(`project/${workspaceId}/${projectId}/API?action=project_metadata`)
    },
	async search({ workspaceId, projectId, term, layer, user, point }: ProjectSearchDto) {
		let entity: string[] = []
		if (layer) entity.push('layer')
		if (user) entity.push('user')
		if (point) entity.push('point')

    return api.get<ProjectSearchResponse>(
      `project/${workspaceId}/${projectId}/API?action=search&what=${term
        .split(' ')
        .join('-d3cf70da57184b0da70626d8fe49248f-')}&entity=${entity.join(
        '%2C'
      )}&limit=100`
    )
  },
  async searchV2({
    workspaceId,
    projectId,
    term,
    layer,
    user,
    point
  }: ProjectSearchDto) {
    let entity: string[] = []
    if (layer) entity.push('layer')
    if (user) entity.push('user')
    if (point) entity.push('point')

    const searchTerm = String(
      term.split(' ').join('-d3cf70da57184b0da70626d8fe49248f-')
    )

    return api.get<ProjectSearchResponse>(
      `project/${workspaceId}/${projectId}/API?action=libSphinx2Search&what=${searchTerm}&entity=${entity.join(
        '%2C'
      )}&limit=100`
    )
  },
  async getTree({ workspaceId, projectId }: ProjectTreeDto) {
    return api.get<ProjectTreeResponse>(
      `project/${workspaceId}/${projectId}/API?action=project_tree`
    )
  }
}


interface ProjectTreeDto {
    workspaceId: number
    projectId: number
}
export interface ProjectSearchDto {
    workspaceId: number
    projectId: number
	term: string
	layer: boolean
	user: boolean
	point: boolean
}

export interface ProjectMetadataDto {
    workspaceId: number
    projectId: number
}

export interface ProjectTreeResponse extends ApiResponseType {
    tree: ProjectTree[]
}
export interface ProjectTree {
    color: string
    font_size: number
    borderwidth: number
    font_options: number
    wrap_text: boolean
    clip_text: boolean
    scale_text: boolean
    label_size: number
    childs: ProjectTree[]
    plugin_data: any
    id: number
    key: string
    name: string
    type_uid: string
    parent_id: number | null
    parent_key: number | null
    own_view: boolean
    sort: number
}


export interface ProjectSearchResponse extends ApiResponseType {
    search_result: any
}

export interface SearchItem {
    id: number
    type: "node" | "user" | "layer"
}

export interface ProjectMetadataResponse extends ApiResponseType {
    metablock: MetablockData
    project_access: "admin" | "client" | "none"
    project_user_data: {
        plugin_data: any
    }
}

export interface MetablockNodes {
    [key: string]: MetadataNode
}

export interface MetablockData {
	id: number
	workspace_id: number
	name: string
	workspace_name: string
	info: string;
	// layers: Layer;
	nodes: MetablockNodes
	plugins: string[]
    layers: any
}

export interface MetadataNode {
    background: string;
	border: string;
	radius: number;
	uid: string;
	name: string;
	anywhere: boolean;
	info?: any;
	containers: string[];
    plugin_data: any
	icon: NodeIcon;
}

export interface NodeIcon {
    sort: number;
	context_name: string;
	owner_id: string;
	image_id: string;
	mime: string;
	valid: boolean;
	extension: string;
	mime_safe: string;
}