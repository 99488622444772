import { BASE_DOMAIN } from '@/api'
import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer'
import { useLayerInfo } from '@/api/hooks/useLayerInfo'
import { useLayerView } from '@/api/hooks/useLayerView'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useTree } from '@/api/hooks/useTree'
import { PointType } from '@/api/layer'
import { defaultNodeImage } from '@/components/SeatBar'
import { linkLayerInfoProperties } from '@/components/layout/Sidebar/Layers/LayerInfoModal'
import { checkHidden } from '@/components/layout/Sidebar/Layers/TreeItem'
import MapStage from '@/components/shared/map/stage/MapStage'
import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useProjectStore } from '@/stores/projectStore'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import media from '@/ui/media'
import { formatLocalDateToAPI } from '@/utils/helpers/dates.helpers'
import { lowerCaseString } from '@/utils/helpers/string.helpers'
import { addMinutes } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import PointsLayer from '../point/PointsLayer'
import PolygonsLayer from '../polygon/PolygonsLayer'
import Tooltip from '../tooltip/Tooltip'
import MapCoverLayer from './MapCoverLayer'

const dispatchLoaded = () => {
	const event = new CustomEvent('map-loaded')
	document.dispatchEvent(event)
}

export const isInventoryLayer = (node, meta) => {
	if (!node || !meta) return null
	const layerMeta = meta[node.type_uid]
	if (!layerMeta) return null

	// @ts-ignore
	const layerPlugins = Object.values(layerMeta.plugin_data).find(
		(v) => v?.['fields'],
	)?.['fields']
	const layerField = layerPlugins?.find(
		(plugin) => plugin.name === '#inventory',
	)

	const layerFieldId = layerField?.id
	if (!layerFieldId) return false

	let inventoryLayer = false
	// @ts-ignore
	const nodePlugins =
		Object.values(node.plugin_data).find<Array>(
			(v) => v && typeof v === 'object' && Array.isArray(v),
		) ?? []

	nodePlugins.forEach((plugin: any) => {
		if (plugin['field_id'] == layerFieldId) {
			inventoryLayer = plugin['value']
		}
	})

	return inventoryLayer
}

const MapContainer = () => {
	const activeLayer = useGlobalStore((state) => state.activeLayer)
	const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
	const setLayerModal = useGlobalStore((state) => state.setLayerModal)
	const selection = useGlobalStore((state) => state.selection)
	const nodes = useProjectStore((state) => state.nodes)
	const setMapLayer = useMapStore((state) => state.setMapLayer)
	const setPopupLayer = useGlobalStore((state) => state.setPopupLayer)
	const { metadata } = useMetadata()

	const apiUrl = useSettingsSelector(
		(settings) => settings.api.url,
		BASE_DOMAIN,
	)
	const viewTypes = useSettingsSelector(
		(settings) => settings.inventory.view,
		[],
	)
	const nodeImageSettings = useSettingsSelector(
		(settings) => settings.inventory,
		defaultNodeImage as any,
	)
	const sourceType = useSettingsSelector(
		(settings) => settings.map.images,
		'anonymous',
	)
	const hideLayers = useSettingsSelector(
		(settings) => settings.hideLayers,
		false,
	)
	const role = useUserStore((state) => state.role)
	const isAdmin = role === RolesEnum.Admin
	const [type, setType] = useState<'layer' | 'inventory'>(
		isAdmin ? 'layer' : 'inventory',
	)
	const [viewType, setViewType] = useState<'layer' | 'inventory'>('layer')

	const node = nodes.find((n) => n.id == activeLayer)
	const hasOwnView = node?.ownView
	const { layers } = useTree()
	const layer = hasOwnView ? node.id : node?.parent || activeLayer

	const { data: layerInfo } = useLayerInfo(Number(layer))

	const { layerView, isSuccess: layerViewLoading } = useLayerView(Number(layer))
	const { data, isSuccess } = useBookingsForLayer(
		Number(layer),
		formatLocalDateToAPI(selection.startDate),
		formatLocalDateToAPI(addMinutes(selection.endDate, -30)),
	)

	const currentNode = useMemo(() => {
		if (layerInfo && metadata && metadata.layers) {
			return metadata.layers[layerInfo?.info?.type_uid]
		}
		return null
	}, [layerInfo, metadata])

	const props = useMemo(
		() =>
			linkLayerInfoProperties(
				currentNode?.plugin_data,
				layerInfo?.info?.plugin_data,
				false,
			),
		[currentNode, layerInfo],
	)
	const isPopup = useMemo(
		() =>
			!!props.find((prop) => prop.name === 'popup' && prop.value === 'true'),
		[props],
	)
	const isInventory = useMemo(
		() => isInventoryLayer(node, metadata?.layers),
		[node, metadata?.layers],
	)
	const inventoryPic = useMemo(
		() => props.find((prop) => prop.name.startsWith('#inventory-pic')),
		[props],
	)
	// const layer = isInventory ? node?.parent || activeLayer : hasOwnView ? node.id : node?.parent || activeLayer

	const [viewId, setViewId] = useState(layer)
	const [view, setView] = useState<Object | null>(null)

	useEffect(() => {
		setMapLayer(Number(layer))
	}, [layer, activeLayer])

	useEffect(() => {
		if (isSuccess && layerViewLoading) {
			dispatchLoaded()
		}
	}, [isSuccess, layerViewLoading, activeLayer])

	useEffect(() => {
		setType(isAdmin ? 'layer' : 'inventory')
		setViewType('layer')
	}, [activeLayer])

	useEffect(() => {
		if (!layerView || !layerInfo || isInventory == null) return
		const basicLayer = {
			...layerView.view,
			isInventory: isInventory,
			hasInventoryPic: !!inventoryPic,
			layerType: 'layer',
			viewType: type,
		}

		if (isInventory) {
			if (inventoryPic && typeof inventoryPic?.value === 'object') {
				setView({
					...basicLayer,
					layerType:
						!isAdmin && isInventory
							? inventoryPic?.value
								? 'field'
								: 'layer'
							: 'layer',
					viewType: type,
					inventoryImage: inventoryPic?.value,
					layerImage: layerView.view.image,
					image:
						!isAdmin && isInventory
							? inventoryPic?.value || layerView.view.image
							: layerView.view.image,
				})
				return
			} else {
				if (!isAdmin) {
					setLayerModal(Number(layerView.layerId))
					const parentId = nodes.find(
						(l) => l.id == Number(layerView.layerId),
					)?.parent
					if (parentId) {
						setActiveLayer(Number(parentId))
					}
				}
			}
		}
		setView(basicLayer)
	}, [inventoryPic, isInventory, layerView, isAdmin, nodes, layerInfo, type])

	useEffect(() => {
		if (node && isPopup) {
			setViewId(Number(node?.parent))
			setPopupLayer(Number(layer))
		} else if (node && !isPopup) {
			setViewId(layer)
		}
	}, [layer, isPopup, node, isInventory, inventoryPic])

	useEffect(() => {
		setMapLayer(Number(layer))
	}, [viewId, activeLayer])

	// const viewTypeIds = useMemo(() => {
	// 	if (!metadata?.nodes) return []
	// 	return metadata?.nodes.filter(l => viewTypes.some(type => lowerCaseString(l.name).includes(lowerCaseString(type))))
	// }, [viewTypes, metadata?.nodes])

	const accessablePolygons = useMemo(
		() =>
			layerView?.polygons.reverse().filter((polygon) => {
				if (!hideLayers) return true
				const curr = layers?.nodes.find((n) => n.id == polygon.id)
				if (!curr) return true

				const isHidden = checkHidden(curr, metadata)

				return role === RolesEnum.Admin ? true : !isHidden
			}),
		[layers?.nodes, layerView?.polygons],
	)

	const [inventoryNodes, layerNodes] = useMemo(() => {
		let invNodes: PointType[] = []
		let lNodes: PointType[] = []
		
		layerView?.points?.forEach(n => {
			if (viewTypes.some(type => lowerCaseString(n.type_name).includes(lowerCaseString(type)))) {
				invNodes.push(n)
				return
			}
			lNodes.push(n)
		})
		
		return [invNodes, lNodes]
	}, [layerView?.points])

	const currentNodes = isInventory ? layerView?.points : viewType === 'inventory' ? inventoryNodes : layerNodes

	const renderMap = () => {
		if (!isAdmin && isInventory) {
			return null
		}

		if (type === 'inventory' && isInventory) {
			return null
		}

		return (
			<>
				<PolygonsLayer meta={metadata?.layers} polygons={accessablePolygons} />
				<PointsLayer
					nodes={metadata?.rawNodes}
					points={currentNodes}
					options={layerView?.options}
					bookings={data?.bookings}
					url={apiUrl}
					sourceType={sourceType}
					nodeImageSettings={nodeImageSettings}
					nodesMeta={metadata?.nodes}
				/>
			</>
		)
	}

	if (!isAdmin && isInventory && typeof inventoryPic?.value !== 'object')
		return null

	return (
		<>
			<MapStage>
				{view && (
					<MapCoverLayer view={view} url={apiUrl} sourceType={sourceType} />
				)}
				{renderMap()}
				<Tooltip />
			</MapStage>
			{isAdmin && inventoryNodes.length && (
				<LayerSwitcher type={viewType} setType={setViewType} nodes />
			)}
			{isAdmin && isInventory && typeof inventoryPic?.value === 'object' && (
				<LayerSwitcher type={type} setType={setType} />
			)}
		</>
	)
}

MapContainer.whyDidYouRender = true

export default MapContainer

const LayerSwitcher = ({ type, setType, nodes = false }) => {
	const handleTypeChange = (newType) => {
		setType(newType)
	}

	return (
		<SwitcherWrapper $reverse={nodes}>
			<SwitcherButton type={type} variant={nodes ? 'inventory' : 'layer'} onClick={handleTypeChange}>
				Инвентаризация
			</SwitcherButton>
			<SwitcherButton
				type={type}
				variant={nodes ? 'layer' : 'inventory'}
				onClick={handleTypeChange}
			>
				Слой
			</SwitcherButton>
		</SwitcherWrapper>
	)
}

const SwitcherButton = ({ type, variant, children, onClick }) => {
	return (
		<Button $active={type === variant} onClick={onClick.bind(null, variant)}>
			{children}
		</Button>
	)
}

const SwitcherWrapper = styled.div<{ $reverse: boolean }>`
	position: absolute;
	bottom: 32px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10;
	background: #fff;
	border-radius: 6px;
	display: flex;
	align-items: center;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

	${props => props.$reverse ? css`
		flex-direction: row-reverse;
	` : css`
		flex-direction: row;
	`}

	${media.lg`
		bottom: 110px;
	`}
`

const Button = styled.div<{ $active: boolean }>`
	padding: 16px;
	cursor: pointer;

	${(props) =>
		props.$active
			? css`
					background: red;
					color: #fff;
					font-weight: 700;
			  `
			: css`
					background: transparent;
			  `}
`
