import { API } from '@/api'
import { loadTimestamp } from '@/App'
import { multiBookingsView } from '@/components/MultiBookingsView'
import { getNodeExtraImage } from '@/components/SeatBar'
import ExclamationMark from '@/images/voskl.svg'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useUserStore } from '@/stores/userStore'
import { animated, Spring } from '@react-spring/konva'
import { easePoly } from 'd3-ease'
import qs from 'query-string'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Group, Rect, Text } from 'react-konva'
import { Portal } from 'react-konva-utils'
import styled from 'styled-components'
import useImage from 'use-image'
import { getMapCoord } from '../polygon/Polygon'

export const defaultMapPointImage = {
	owner_id: '15',
	image_id: '2d95a0df350c4c2dae7ab2c0c03bf337',
	extension: 'png',
}

export const getImageLink = (image, url: string) => {
	if (!image || !image.owner_id) return ''
	return `${url}media/metablock/${image.owner_id}/${image.image_id}.SW100H100!default.${image.extension}?v=${loadTimestamp}`
}

const useMapAvatar = (bookingData: any, node: any, url: string, sourceType: 'anonymous' | 'use-credentials', nodesMeta: any, nodeImageSettings: any, defaultMapPointImage: string) => {
    const [imageQueue, setImageQueue] = useState<string[]>([]);
    const [retryCount, setRetryCount] = useState<number>(0);

   
    useEffect(() => {
		const getQueue = () => {
			const q: Array<string | null> = [];
			if (bookingData) {
				q.push(API.user.getAvatar({
					userId: Number(bookingData.user_id),
					height: 100,
					width: 100,
					cache: bookingData['user_uts'],
					url,
				}));
			}
	
			q.push(getNodeExtraImage(nodesMeta, { id: node?.id, type_uid: node?.type_uid }, nodeImageSettings, url));
	
			setImageQueue(q.filter(v => v) as string[]);
		}

        getQueue();
    }, [bookingData]);

    useEffect(() => {
        if (retryCount >= 10 && imageQueue.length) {
        	const [_, ...rest] = imageQueue
			    setImageQueue(rest)
        }
    }, [retryCount]);

	const pointImgSrc = getImageLink(node?.icon, url) || getImageLink(defaultMapPointImage, url)
	const [pointImg, pointImgStatus] = useImage(pointImgSrc, sourceType);

	const imgSrc = useMemo(() => {
		const link = imageQueue.at(0)
		if (typeof link !== 'string' ) return ''
		const parsedURL = qs.parseUrl(link)
		parsedURL.query.retry = String(retryCount)

		return qs.stringifyUrl(parsedURL)
	}, [retryCount, imageQueue])

	const [img, imgStatus] = useImage(imgSrc, sourceType);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (imgStatus === 'failed') {
			timer = setTimeout(() => {
				setRetryCount(prevRetryCount => prevRetryCount + 1);
			}, 5000);
		}
	
		return () => clearTimeout(timer)
	}, [imgStatus])

    return { image: img ?? pointImg }
};


export const isBookable = (data) => {
	if (!data) return false
	const plugin: any = Object.values(data).find((obj: any) => obj?.bookable)
	if (!plugin) return false
	return plugin.bookable
}

export const isBookableForMe = (data, categories) => {
	if (!data) return false
	const plugin: any = Object.values(data).find((obj: any) => obj?.bookable)
	if (!plugin) return false
	const category = plugin.category
	const bookable = plugin.bookable
	const isAvailableForCategory = categories.includes(category)
	const isAvailableForBooking =
		isAvailableForCategory === true && bookable == true

	return isAvailableForBooking
}

const defaultNode = {
	background: '#ffffff',
	border: '#000000',
	radius: 50,
	uid: 'ae4c38a5a5994d8082029b51370111a3',
	name: 'Сервер',
}

const BookingsViewWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	max-height: 100%;
	max-width: 400px;
	min-height: 90px;
	width: 100%;
	z-index: 9101;
	overflow-y: auto;
	background: #ffffff;
	box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
`

const formatName = (name: string) => {
	return name
		.split(' ')
		.map((n, idx) => (idx === 0 ? n + ' ' : n[0] + '.'))
		.join('')
}

export const linkPointProperties = (node, meta) => {
	try {
		if (!node || !meta) return []
		const objs = Object.values(node)

		const data = objs.find((item) =>
			Object.keys(item as any)?.find((key) => key.includes('field')),
		)
		const nodeData = Object.values(meta)
			.filter((v) => v)
			.find((item) => item?.fields)?.fields

		if (!data || !nodeData) return []
		const props = nodeData
			.map((item) => {
				const info = data['field_' + item.id]

				if (!info || typeof info.value === 'object') return null

				return {
					name: String(item.name),
					value: String(info),
					sort: item.sort,
				}
			})
			.filter((v) => v)

		return props
	} catch (e) {
		return []
	}
}

const Point = ({ point, options, nodes, bookings, url, sourceType, nodeImageSettings, nodesMeta }) => {
	const [show, setShow] = useState<boolean>(false)
	const categories = useUserStore((state) => state.categories)
	const textRef = useRef<any>(null)
	const groupRef = useRef<any>(null)
	const [exclamation, status] = useImage(ExclamationMark, sourceType)
	const { id, x, y, name, type_uid, plugin_data } = point
	const { labelSize, fontSize, color, borderWidth, wrapText } = options

	const bookingsArr = useMemo(
		() => bookings?.filter((book) => book.point_id == id) || [],
		[bookings, id],
	)

	const bookingData = useMemo(
		() => bookings?.find((book) => book.point_id == id),
		[bookings, id],
	)
	const avaliable = useMemo(
		() => isBookableForMe(plugin_data, categories),
		[plugin_data, categories],
	)

	const node = nodes[type_uid] || defaultNode
	const seatName = wrapText ? name.split(' ').join('\n') : name

	const bookingCustomData =
		bookingData?.booking_custom_data?.replace(/[,.;]/g, '\n') ?? ''
	const displayText = bookingData?.display
		? [formatName(bookingData?.display || ''), seatName]
				.filter((value) => !!value)
				.join('\n')
		: seatName
	const props = useMemo(
		() => linkPointProperties(point?.plugin_data, node?.plugin_data),
		[point?.plugin_data, node?.plugin_data],
	)
	const isClosed =
		props.findIndex(
			(prop) =>
				prop.name === '#remont' &&
				(String(prop.value) === 'true' ||
					(typeof prop.value === 'boolean' && prop.value)),
		) +
			1 >
		0

	const [coord, setCoord] = useState({ x: 0, y: 0, pX: 0 })
	const { image } = useMapAvatar(bookingData, { ...node, id: point.id, type_uid: point.type_uid }, url, sourceType, nodesMeta, nodeImageSettings)

	const seat = useGlobalStore((state) => state.seat)
	const setSeat = useGlobalStore((state) => state.setSeat)
	const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
	const [width, height] = useMapStore((state) => state.size)
	const setTooltip = useMapStore((state) => state.setTooltip)

	const [hover, setHover] = useState(false)

	const onSelect = useCallback(() => {
		if (bookingsArr.length > 1) {
			multiBookingsView({ bookings: bookingsArr, name })
			return
		}
		setSeat(Number(point.id))
		if (bookingData) {
			setSeatEmployee(Number(bookingData.user_id))
		} else {
			setSeatEmployee(null)
		}
	}, [setSeat, setSeatEmployee, bookingData, point])

	const onMouseEnterHandler = React.useCallback((e) => {
		const container = e.target.getStage()?.container()

		handleShow()
		if (container) {
			container.style.cursor = 'pointer'
		}
	}, [])

	const onMouseLeaveHandler = React.useCallback((e) => {
		const container = e.target.getStage()?.container()
		handleHide()
		if (container) {
			container.style.cursor = 'default'
		}
	}, [])

	const onGroupMouseEnterHandler = (e) => {
		const book = bookings?.find((book) => book.point_id == id)
		const bookingCustomData =
			book?.booking_custom_data?.replace(/[,.;]/g, '\n') || ''
		setTooltip(name + '\n' + bookingCustomData)
		setHover(true)
	}

	const onGroupMouseLeaveHandler = React.useCallback(
		(e) => {
			setTooltip(null)
			setHover(false)
		},
		[setTooltip],
	)

	const currentSeat = useMemo(() => seat == id, [seat, id])

	useEffect(() => {
		if (width > 0 && height > 0) {
			const pointX =
				-textRef.current?.textWidth / 2 + (width * labelSize) / 2 ||
				(width * labelSize) / 2
			setCoord({
				x: getMapCoord(width, x),
				y: getMapCoord(height, y),
				pX: Number(pointX),
			})
		}
	}, [
		textRef.current?.width,
		displayText,
		labelSize,
		x,
		y,
		image,
		width,
		height,
	])

	const handleShow = () => {
		// if (isAdmin) return
		if (bookingData?.display) {
			setShow(true)
		}
	}
	const handleHide = () => {
		// if (isAdmin) return
		setShow(false)
	}

	const renderPoint = () => {
		if (bookingsArr.length > 1) {
			const namesSet = new Set(bookingsArr.map((book) => book.display || ''))
			const names = [...namesSet].join('\n')
			return (
				<>
					<Group>
						<Rect
							width={width * labelSize}
							height={width * labelSize}
							stroke="#000"
							strokeWidth={fontSize * borderWidth}
							fill="#fff"
							cornerRadius={node?.radius}
							shadowForStrokeEnabled={false}
							perfectDrawEnabled={false}
							listening={avaliable}
						/>
						<Text
							ref={textRef}
							text={bookingsArr.length}
							align="center"
							verticalAlign="middle"
							fontSize={fontSize * 1.5}
							fill="#000"
							x={(width * labelSize) / 1.5 - fontSize / 1.3}
							y={(width * labelSize) / 1.5 - fontSize}
							listening={true}
							perfectDrawEnabled={false}
						/>
					</Group>
					<Portal selector=".top-layer" enabled={show}>
						{show && (
							<Group
								x={coord.x + coord.pX - 2.5}
								y={coord.y + width * 1.1 * labelSize}
								offsetX={(width * labelSize) / 2}
								offsetY={(width * labelSize) / 2}
							>
								<Rect
									width={textRef.current?.width() + 5}
									height={textRef.current?.height() + 5}
									fill={'rgba(255,255,255,0.9)'}
									cornerRadius={4}
									shadowForStrokeEnabled={false}
									perfectDrawEnabled={false}
									listening={true}
								/>
								<Text
									ref={textRef}
									text={names}
									align="center"
									verticalAlign="middle"
									fontSize={fontSize}
									fill={color}
									x={2.5}
									y={2.5}
									listening={true}
									perfectDrawEnabled={false}
								/>
							</Group>
						)}
					</Portal>
				</>
			)
		}

		return (
			<>
				<Rect
					width={width * labelSize}
					height={width * labelSize}
					stroke={node?.border}
					strokeWidth={fontSize * borderWidth}
					fill={node?.background}
					cornerRadius={node?.radius}
					shadowForStrokeEnabled={false}
					perfectDrawEnabled={false}
					listening={avaliable}
				/>
				{image && (
					<Rect
						width={width * labelSize}
						height={width * labelSize}
						cornerRadius={node?.radius}
						fillPatternImage={image}
						fillPatternScaleX={(width * labelSize) / image?.width}
						fillPatternScaleY={(width * labelSize) / image?.width}
						shadowForStrokeEnabled={false}
						perfectDrawEnabled={false}
					/>
				)}
				<Group
					y={width * 1.1 * labelSize}
					x={coord.pX - (textRef.current?.width() * 0.2) / 2}
				>
					<Rect
						width={textRef.current?.width() + textRef.current?.width() * 0.2}
						height={textRef.current?.height() + textRef.current?.width() * 0.1}
						fill={
							currentSeat || hover
								? 'rgba(255,255,255,1)'
								: 'rgba(255,255,255,0.5)'
						}
						cornerRadius={4}
						shadowForStrokeEnabled={false}
						perfectDrawEnabled={false}
						listening={true}
					/>
					<Text
						// width={textRef.current?.width() + 5}
						// height={textRef.current?.height() + 5}
						ref={textRef}
						text={displayText}
						align="center"
						verticalAlign="middle"
						fontSize={fontSize}
						fill={color}
						x={(textRef.current?.width() * 0.2) / 2}
						y={(textRef.current?.width() * 0.1) / 2}
						listening={true}
						perfectDrawEnabled={false}
					/>
				</Group>
				{isClosed && exclamation && (
					<Group y={0} x={width * labelSize * 0.8}>
						<Rect
							width={width * labelSize * 0.4}
							height={width * labelSize * 0.4}
							cornerRadius={9999}
							fill="#fff"
							stroke="#000"
							strokeWidth={fontSize * borderWidth * 0.7}
							shadowForStrokeEnabled={false}
							perfectDrawEnabled={false}
							listening={true}
						/>
						<Rect
							width={width * labelSize * 0.4}
							height={width * labelSize * 0.4}
							cornerRadius={node?.radius}
							fillPatternImage={exclamation}
							fillPatternScaleX={(width * labelSize * 0.4) / exclamation?.width}
							fillPatternScaleY={(width * labelSize * 0.4) / exclamation?.width}
							shadowForStrokeEnabled={false}
							perfectDrawEnabled={false}
						/>
					</Group>
				)}
			</>
		)
	}

	return (
		<Group
			x={coord.x}
			y={coord.y}
			offsetX={(width * labelSize) / 2}
			offsetY={(width * labelSize) / 2}
			onClick={onSelect}
			onTap={onSelect}
			listening={true}
		>
			<Group
				onMouseEnter={(e) => {
					onMouseEnterHandler(e)
					onGroupMouseEnterHandler(e)
				}}
				onMouseLeave={(e) => {
					onMouseLeaveHandler(e)
					onGroupMouseLeaveHandler(e)
				}}
				// opacity={avaliable ? 1 : 0.3}
				id={'point' + point.id}
				ref={groupRef}
			>
				{renderPoint()}
			</Group>
		</Group>
	)
}

export const scaleRelativeToPoint = (stage, point, scale, onRescale) => {
	const scaleBy = 1.25
	const oldScale = stage.scaleX()

	const mousePointTo = {
		x: point.x / oldScale - stage.x() / oldScale,
		y: point.y / oldScale - stage.y() / oldScale,
	}

	const newScale = scale
	const x = (point.x / newScale - mousePointTo.x) * newScale
	const y = (point.y / newScale - mousePointTo.y) * newScale

	onRescale({
		scale: newScale,
		point: {
			x,
			y,
		},
	})
}

export const AnimatedPoint: React.FC<any> = React.memo((props) => {
	const { id } = props

	const seat = useGlobalStore((state) => state.seat)
	const currentSeat = useMemo(() => seat == id, [seat, id])

	return (
		<Spring
			from={{ opacity: 1 }}
			to={
				currentSeat
					? [
							{ opacity: 0.2 },
							{ opacity: 1 },
							{ opacity: 0.2 },
							{ opacity: 1 },
							{ opacity: 0.2 },
							{ opacity: 1 },
							{ opacity: 0.2 },
							{ opacity: 1 },
					  ]
					: { opacity: 1 }
			}
			config={{
				easing: easePoly.exponent(2),
				duration: 700,
			}}
		>
			{(springProps) => (
				// @ts-ignore
				<animated.Group {...springProps}>
					<Point {...props} />
				</animated.Group>
			)}
		</Spring>
	)
})

AnimatedPoint.whyDidYouRender = true

export default React.memo(Point)
