import React from 'react'
import styled from 'styled-components'
import { useGlobalStore } from '@/stores/globalStore'
import { media } from '@/ui/media'
import { useLayerInfo } from '@/api/hooks/useLayerInfo'
import { translate } from '@/i18n'
import { useTree } from '@/api/hooks/useTree'

const FloorBadge = () => {
  const { layers } = useTree()
  const activeLayer = useGlobalStore((state) => state.activeLayer)

  const { data, isLoading } = useLayerInfo(activeLayer)

  if (!data || (!data.info && !isLoading) || layers?.layer?.id == activeLayer)
    return null

  const badge = () => {
    const hasOwnView = data.info.own_view
    return hasOwnView
      ? [...data.info.parent_name, data.info.name]
      : data.info.parent_name
  }

  return (
    <Wrapper>{isLoading ? translate('loading') : badge().join('. ')}</Wrapper>
  )
}

export default FloorBadge

const Wrapper = styled.div`
  background: #d2233c;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #ffffff;
  padding: 1rem;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 95px;
  z-index: 199;
  user-select: none;

  pointer-events: none;

  ${media.lg`
        left: 10px;
        transform: translateX(0%);
    `}
`
