import { NodeElement } from '@/api/project'
import { Grid } from '@/components/Grid'
import useResponsive from '@/hooks/useResponsive'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import { useProjectStore } from '@/stores/projectStore'
import React from 'react'
import InfoIcon from '../icons/InfoIcon'
import PointIcon from './../icons/PointIcon'
import MobileElementsItem from './MobileElementsItem'

type ElementsListItemProps = {
  item: NodeElement
}

const ElementsListItem: React.FC<ElementsListItemProps> = ({ item }) => {
  const setSeat = useGlobalStore((state) => state.setSeat)
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
  const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
  const setZoomSeat = useMapStore((state) => state.setZoomSeat)
  const { isDesktop } = useResponsive()

  const handleSeatView = (e) => {
    e.preventDefault()

    setActiveLayer(Number(item.parent))
    setSeatEmployee(null)
    setSeat(Number(item.id))
    setZoomSeat({ seat: Number(item.id), parent: Number(item.parent) })
  }

  const handleSeatChange = (e) => {
    e.preventDefault()
    setSeatEmployee(null)
    setSeat(Number(item.id))
  }

  if (!isDesktop)
    return (
      <MobileElementsItem
        name={item.name}
        item={item}
        handleNavigate={handleSeatView}
        openSeat={handleSeatChange}
      />
    )

  return (
    <Grid.Row $cols="2fr 1fr 1fr 30px 30px">
      <Grid.Item>{item.name}</Grid.Item>
      <Grid.Item>
        <Place item={item} />
      </Grid.Item>
      <Grid.Item>{item.type_name}</Grid.Item>
      <Grid.Item>
        <a href="#" onClick={handleSeatView}>
          <PointIcon color="#666" />
        </a>
      </Grid.Item>
      <Grid.Item>
        <a href="#" onClick={handleSeatChange}>
          <InfoIcon  color="#666" />
        </a>
      </Grid.Item>
    </Grid.Row>
  )
}

export default ElementsListItem

export const Place = ({ item }) => {
  const nodes = useProjectStore((state) => state.nodes)
  const place = nodes.find((tree) => tree.id == item.parent)

  return <div>{place ? place.name : 'Не указано'}</div>
}
