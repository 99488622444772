import { useMetadata } from '@/api/hooks/useMetadata'
import { useNodes } from '@/api/hooks/useNodes'
import { NodeElement } from '@/api/project'
import { MetadataNode } from '@/api/services/project.service'
import Close from '@/components/Close'
import { StaticModal } from '@/components/Modal/Modal'
import useResponsive from '@/hooks/useResponsive'
import useSorting from '@/hooks/useSorting'
import { translate } from '@/i18n'
import { useProjectStore } from '@/stores/projectStore'
import Pagination from '@/ui/components/Pagination/Pagination'
import media from '@/ui/media'
import { printPDF } from '@/utils/func/print'
import { formatToReport } from '@/utils/helpers/dates.helpers'
import React, { useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import { ReportButton } from '../../ReportView'
import MobileInventoryItem from './/MobleInventoryItem'
import Loader from '@/components/Preloader/Loader'
import FormLoader from '@/components/ui/form/FormLoader'

type ReportModalProps = {
  report: string | null
  item: {
    name: string
    key: string
  }
  close: () => void
}

const getMetaFields = (meta: MetadataNode | undefined): Array<any> => {
  if (!meta) return []
  const fields = Object.values(meta.plugin_data as Record<any, any>).find((data) => data['fields'])?.['fields']
  return fields ? fields : []
}

const sortFn = (fieldName: string, direction: 0 | 1) => function (a, b) {
  const propA = !direction ? a[fieldName] : b[fieldName]
  const propB = !direction ? b[fieldName] : a[fieldName]

  if (!propA) {
    return 1;
  }
  if (!propB) {
      return -1;
  }

  if (propA < propB) {
    return -1;
  }
  if (propA > propB) {
    return 1;
  }
  return 0;
}

const InventoryReportModal: React.FC<ReportModalProps> = ({
  report,
  item,
  close: onClose,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const intl = useIntl()

  const { sort, direction, handleSort } = useSorting('name', 0)
  const { metadata } = useMetadata()
  const nodes = useProjectStore(state => state.nodes)

  const { isDesktop } = useResponsive()


  const { data, isLoading } = useNodes({
    page: currentPage - 1,
    perPage: 2000,
    name: '',
    parent: '',
    type: '',
    sort: '',
    direction,
  })

  const headers = [
    { label: 'Наименование', key: 'name' },
    { label: 'Расположение', key: 'location' },
    { label: 'Тип', key: 'type_name' },
    { label: 'Номенклатурный номер', key: 'nomenclature' },
    { label: 'Инвентарный номер', key: 'inventory' }
  ]

  const reportData = useMemo(() => data?.items.map(item => {
    const nodeMeta = metadata?.nodes.find(node => node.uid == item.type_uid)
    const fields = getMetaFields(nodeMeta)
    const nomenclatureMetaId = fields.find(field => field.name.toLowerCase().includes('номенклатурный'))?.id
    const inventoryMetaId = fields.find(field => field.name.toLowerCase().includes('инвентарный'))?.id

    const fieldNames = Object.keys(item)
    const nomFieldName = fieldNames.find(field => field.endsWith(nomenclatureMetaId))
    const invNumFieldName = fieldNames.find(field => field.endsWith(inventoryMetaId))
    const place = nodes.find(tree => tree.id == Number(item.parent))

    return {
      ...item,
      location: place?.name,
      nomenclature: nomFieldName ? item[nomFieldName] : null,
      inventory: invNumFieldName ? item[invNumFieldName] : null,
    }
  }).filter(item => [item.nomenclature, item.inventory].some(v => v)) || [], [data, metadata, nodes])

  const reportItems = useMemo(() => reportData.sort(sortFn(sort, direction)), [reportData, sort, direction])
  

  const pdfCols = useMemo(
    () => headers.map((col) => ({ header: col.label, dataKey: col.key })),
    [headers]
  )
  const formatData = useMemo(() => {
    return reportItems
  }, [reportItems])

  const renderReport = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={5}>
            <div style={{ textAlign: 'center', margin: '16px 0' }}>Загрузка отчета...</div>
          </td>
        </tr>
      )
    }

    if (reportItems && reportItems.length) {
      return reportItems
        .slice((currentPage - 1) * 20, currentPage * 20)
        .map((item) => <ElementItem key={item.id} item={item} />)
    }

    return (
      <tr>
        <td colSpan={5}>
          <NotFound>{translate('no-results')}</NotFound>
        </td>
      </tr>
    )
  }

  return (
    <StaticModal onClose={onClose}>
      <Header>
        <Title>
          {translate('report-template', {
            name: intl.formatMessage({ id: item.key })
          })}
        </Title>
        <ExportWrapper>
          <ReportButton
            onClick={() =>
              printPDF({
                name: report,
                columns: pdfCols,
                body: formatData
              })
            }
          >
            PDF
          </ReportButton>
          <CSVLink
            data={formatData}
            headers={headers}
            separator={';'}
            filename={report + ` ${formatToReport(new Date())}`}
          >
            <ReportButton>CSV</ReportButton>
          </CSVLink>
          <Close color="#000" onClick={onClose} />
        </ExportWrapper>
      </Header>

      <Table>
        {isDesktop && (
          <TableHead>
            <tr>
              {headers.map((header) => (
                <TableHeadCell
                  onClick={handleSort.bind(null, header.key)}
                  $active={sort === header.key}
                  $direction={direction}
                  key={header.key}
                >
                  {header.label}
                </TableHeadCell>
              ))}
            </tr>
          </TableHead>
        )}

        <TableBody>
          {renderReport()}
        </TableBody>

      </Table>

      <Pagination
        inverse
        currentPage={currentPage}
        total={reportItems?.length || 0}
        handlePageChange={setCurrentPage}
      />
    </StaticModal>
  )
}

export default InventoryReportModal

const TableHeadCell = styled.th<{
  $active?: boolean
  $direction?: number
}>`
  text-align: left;
  vertical-align: middle;
  position: relative;
  padding-right: 20px;

  &::after {
    opacity: 0;
    content: ' ';
    position: absolute;
    top: 50%;
    right: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='6' width='1.41421' height='8.48527' transform='rotate(45 6 0)' fill='%23000' /%3E%3Crect x='7' y='11' width='1.41421' height='8.48527' transform='rotate(135 7 11)' fill='%23000' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: translateY(-50%) rotate(-90deg);
    height: 12px;
    width: 7px;

    ${({ $active }) =>
      $active &&
      css`
        opacity: 1;
      `}

    ${({ $direction }) =>
      $direction &&
      css`
        transform: translateY(-50%) rotate(90deg);
      `}
  }
`

const TableBody = styled.tbody``

const TableHead = styled.thead`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`

const Table = styled.table`
  margin: 1rem 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  tr td:last-child {
    width: 1%;
    white-space: nowrap;
  }
`

const TableCell = styled.td`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 0;
`

const ElementItem: React.FC<{ item: NodeElement & { nomenclature: string | undefined, inventory: string | undefined, location: string | undefined } }> = ({ item }) => {

  const { isDesktop } = useResponsive()

  if (!isDesktop)
    return (
      <MobileInventoryItem
        name={item.name}
        type_name={item.type_name}
        location={item.location}
        nomenclature={item.nomenclature}
        inventory={item.inventory}
      />
    )

  return (
    <tr>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.location}</TableCell>
      <TableCell>{item.type_name}</TableCell>
      <TableCell>{item.nomenclature}</TableCell>
      <TableCell>{item.inventory}</TableCell>
    </tr>
  )
}

const NotFound = styled.div`
  width: 100%;
  text-align: center;
  padding: 12px 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lg`
      flex-direction: column;
      align-items: flex-start;
  `}
`

const ExportWrapper = styled.div`
  display: flex;
  align-items: center;

  * {
    &:not(:last-child) {
      margin-right: 0.8rem;
    }
  }

  ${media.lg`
      margin-top: 20px;
  `}
`

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`
