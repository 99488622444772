import React from 'react'
import styled from 'styled-components';
import MapStatus from './stage/MapStatus';
import MapContainer from './stage/MapContainer';
import MapController from './stage/MapController';
import MapTooltip from './tooltip/MapTooltip';

const Map: React.FC = () => {
    return (
        <MapWrapper>
            <MapContainer />
            <MapStatus />
            <MapController />
            <MapTooltip />
        </MapWrapper>
    )
}

Map.whyDidYouRender = true

export default Map

const MapWrapper = styled.div`
    position: relative;
`