import React, { useEffect, useRef, useState } from 'react'
import { useMapStore } from '@/stores/mapStore'
import styled, { css } from 'styled-components'

const TooltipWrapper = styled.div<{ $visible: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	will-change: transform;
	pointer-events: none;
	opacity: 0;
	visibility: hidden;

	${({ $visible }) =>
		$visible &&
		css`
			opacity: 1;
			visibility: visible;
		`}
`

const Wrapper = styled.div`
	background: #000000;
	border-radius: 10px;
	padding: 5px 10px;
	font-family: 'Roboto';
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #ffffff;
	white-space: pre-wrap;
	text-align: center;

	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -10px;
		border-width: 10px;
		border-style: solid;
		border-color: black transparent transparent transparent;
	}
`

const MapTooltip = () => {
	const ttRef = useRef(null)
	const tooltipPoint = useMapStore((state) => state.tooltipPoint)
	const tooltip = useMapStore((state) => state.tooltip)

	const [visible, setVisible] = useState(false)

	// @ts-ignore
	const containerWidth = ttRef.current?.offsetWidth / 2 || 0

	let timer

	useEffect(() => {
		if (!tooltip) {
			clearTimeout(timer)
			setVisible(false)
			return
		} else {
			setVisible(false)
		}

		timer = setTimeout(function () {
			setVisible(true)
		}, 500)

		return () => {
			clearTimeout(timer)
		}
	}, [tooltip])

	return (
		<TooltipWrapper
			ref={ttRef}
			$visible={visible}
			style={{
				transform: `translateX(${
					tooltipPoint.x - containerWidth
				}px) translateY(${tooltipPoint.y - 20}px)`,
			}}
		>
			<Wrapper>{tooltip}</Wrapper>
		</TooltipWrapper>
	)
}

export default MapTooltip
