import { useReport } from '@/api/hooks/useReport'
import { IReport } from '@/api/services/report.service'
import Close from '@/components/Close'
import Grid from '@/components/Grid'
import Modal, { StaticModal } from '@/components/Modal/Modal'
import FormLoader from '@/components/ui/form/FormLoader'
import { useProjectStore } from '@/stores/projectStore'
import Pagination from '@/ui/components/Pagination/Pagination'
import media from '@/ui/media'
import { formatToReport } from '@/utils/helpers/dates.helpers'
import React, { useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import { printPDF } from '@/utils/func/print'
import { useIntl } from 'react-intl'
import { translate } from '@/i18n'
import { BomjMapper } from '@/components/layout/Sidebar/Reports/reports/bomj-report/bomj.mapper'
import { useUserCardFields } from '@/api/hooks/useUserCardFields'

type ReportModalProps = {
	report: IReport
	close: () => void
}

const pick = (obj, ...keys) =>
	Object.fromEntries(
		keys.filter((key) => key in obj).map((key) => [key, obj[key]]),
	)

const BomjReportView: React.FC<ReportModalProps> = ({
	report,
	close: onClose,
}) => {
	const [currentPage, setCurrentPage] = useState<number>(1)

	const nodes = useProjectStore((state) => state.nodes)
	const intl = useIntl()
	const { data, isLoading } = useReport({
		reportId: report.report_id,
		params: {
			withExtensions: true,
		},
	})

	const { data: userFields } = useUserCardFields()

	const userProps =
		useMemo(
			() =>
				userFields?.fields
					.filter((v) => v.alias != 'booking_category')
					.map((field) => ({
						label: field.label,
						key: field.uid,
						alias: field.alias,
					})),
			[userFields],
		) || []
	const { columns: userColumns = [], translations: propsTranslations } = useMemo(
		() => BomjMapper.getReportColumns(data?.report || [], userProps),
		[data, userProps],
	)

	const translations = {
		type_name: intl.formatMessage({ id: 'report-type_name' }),
		name: intl.formatMessage({ id: 'report-name' }),
		parent: intl.formatMessage({ id: 'report-parent' }),
		display: intl.formatMessage({ id: 'employee' }),
		...propsTranslations,
	}
	const columns = useMemo(() => userColumns.filter(col => !translations[col].startsWith('#')), [userColumns, translations])
	const viewOnly = useMemo(() => userColumns.find(col => String(translations[col]).toLocaleLowerCase() === '#viewonly') || '', [translations])

	const reportItems =
		data?.report.map((item) => {
			const extensionKey = userProps.find((v) =>
				['extensionattribute15'].includes(v.alias),
			)?.key
			const titleKey = userProps.find((v) => ['title'].includes(v.alias))?.key

			const items = {}
			Object.entries(item).forEach(([key, value]) => {
				if (titleKey && extensionKey && key === titleKey) {
					const extensionValue = item[extensionKey]
					const line = [value, extensionValue]
						.filter((v) => v)
						.join(' ')
						.split('>')
						.join('\n')
					items[key] = line
					return
				}

				items[key] = value
			})

			return items
		}).filter(item => !(item[viewOnly] == '1' || item[viewOnly] == 'true' || item[viewOnly] == true)) || []

	const formatData = useMemo(() => {
		return data
			? reportItems.map((item) => {
					const nodeType = nodes.find((node) => node.id == item.parent)

					return {
						...item,
						parent: nodeType?.name,
					}
			  })
			: []
	}, [reportItems])

	const pdfCols = useMemo(
		() => columns.map((col) => ({ header: translations[col], dataKey: col })),
		[columns],
	)

	return (
		<StaticModal onClose={onClose}>
			<Header>
				<Title>
					{translate('report-template', {
						name: intl.formatMessage({ id: report.name }),
					})}
				</Title>
				<ExportWrapper>
					<ReportButton
						onClick={() =>
							printPDF({
								name: intl.formatMessage({ id: report.name }),
								columns: pdfCols,
								body: formatData,
							})
						}
					>
						PDF
					</ReportButton>
					<CSVLink
						data={formatData || ''}
						headers={columns.map((col) => ({
							label: translations[col],
							key: col,
						}))}
						separator={';'}
						filename={`${intl.formatMessage({
							id: report.name,
						})} ${formatToReport(new Date())}`}
					>
						<ReportButton>CSV</ReportButton>
					</CSVLink>
					<Close color="#000" onClick={onClose} />
				</ExportWrapper>
			</Header>

			<Grid style={{ position: 'relative' }}>
				<Grid.RowHeader $cols={`repeat(${columns.length}, minmax(50px, 1fr))`}>
					{columns.map((col) => (
						<Grid.Item key={col}>{translations[col]}</Grid.Item>
					))}
				</Grid.RowHeader>

				{reportItems.length ? (
					reportItems
						.slice((currentPage - 1) * 20, currentPage * 20)
						.map((spot) => (
							<ReportItem key={spot.id} columns={columns} item={spot} />
						))
				) : (
					<NotFound>{translate('no-results')}</NotFound>
				)}

				<FormLoader isLoading={isLoading} />
			</Grid>

			<Pagination
				inverse
				currentPage={currentPage}
				total={reportItems.length || 0}
				handlePageChange={setCurrentPage}
			/>
		</StaticModal>
	)
}

export default BomjReportView

const ReportItem = ({ item, columns }) => {
	// console.log(item, columns)
	const nodes = useProjectStore((state) => state.nodes)

	const cols = columns
		.map((column) => {
			let data = item[column]

			if (!data) return null

			if (column == 'parent') {
				const uid = data
				const nodeType = nodes.find((node) => node.id == data)
				data = nodeType?.name || uid
			}

			return {
				key: column,
				value: data,
			}
		})
		.filter((v) => v)

	return (
		<Grid.Row $cols={`repeat(${columns.length}, minmax(50px, 1fr))`}>
			{cols.map(({ key, value }) => (
				<Grid.Item key={key}>{value}</Grid.Item>
			))}
		</Grid.Row>
	)
}

const NotFound = styled.div`
	width: 100%;
	text-align: center;
	padding: 12px 0;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${media.lg`
        flex-direction: column;
        align-items: flex-start;
    `}
`

const ExportWrapper = styled.div`
	display: flex;
	align-items: center;

	* {
		&:not(:last-child) {
			margin-right: 0.8rem;
		}
	}

	${media.lg`
        margin-top: 20px;
    `}
`

export const ReportButton = styled.button`
	border: 2px solid #d2233c;
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 1.6rem;
	line-height: 1.6rem;
	color: #d2233c;
	padding: 1.2rem 2rem;
	outline: none;
	background: transparent;
	cursor: pointer;
`

const Title = styled.div`
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: #000000;
`
