import { useMapStore } from "@/stores/mapStore";
import { memo } from "react";
import { Layer } from "react-konva";
import Polygon from "./Polygon";

const PolygonsLayer: React.FC<any> = ({ meta, polygons = [] }) => {
    const [width, height] = useMapStore(state => state.size)

    return (
        <Layer>
            {polygons.map(({ id, polygon }) => (
                <Polygon
                    meta={meta}
                    id={id}
                    key={id}
                    width={width}
                    height={height}
                    polygon={polygon}
                />
            ))}
        </Layer>
    )
}

export default memo(PolygonsLayer)

PolygonsLayer.whyDidYouRender = true
