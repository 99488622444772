import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { api } from '@/api'
import { isNonNullable } from '@/utils/type-guards/is-non-nullable'
import { formatURL } from '@/utils/helpers/format-url'
import { fetchJSON } from '@/api/utils'
import { ISettings } from '@/hooks/settings/settings.interface'

const fetchSettings = async (version: number) =>
	await fetchJSON<ISettings>(
		// TODO: mb return cache ?v=
		`/settings.json?v=${version}`,
		'No settings file found',
	)

const getFormattedApiURL = (url: string | undefined | null) => {
	if (!url || !isNonNullable(url)) return '/'

	return formatURL(url.toLowerCase())
}

const getWithCredentials = (param: boolean | string | undefined | null) => {
	if (!isNonNullable(param)) return false
	const lowerCaseParam =
		typeof param === 'boolean' ? String(param) : String(param).toLowerCase()

	return lowerCaseParam == 'true'
}

export const useSettings = () => {
	const { enqueueToast } = useToast()
	return useQuery({
		queryKey: ['settings'],
		queryFn: () => fetchSettings(Date.now()),
		staleTime: 5 * 60 * 1000,
		retry: false,
		// TODO: refactor for v5 @tanstack/react-query where onSuccess and onError will be remover from useQuery
		onSuccess: ({ api: apiSettings }) => {
			if (!apiSettings) return
			api.defaults.baseURL = getFormattedApiURL(apiSettings['url'])
			api.defaults.withCredentials = getWithCredentials(
				apiSettings['withCredentials'],
			)
		},
		select: (data) => ({
			...data,
		}),
		onError: () => {
			enqueueToast(
				{
					title: 'Ошибка',
					message: 'Не удалось загрузить настройки приложения',
				},
				{ variant: 'error' },
			)
		},
	})
}

export default useSettings
