export const MediaService = {
	getNodeFieldImage({ objectId, fieldId, extension, url, cache, opts = { width: 100, height: 100 } }: LayerImageDto) {
		return `${url}media/node_field_image/${objectId}/${fieldId}.SW${opts.width}H${opts.height}!default.${extension}${cache ? `?v=${cache}` : ''}`
	},
}

interface LayerImageDto {
	objectId: string | number
	fieldId: string
	extension: string
	url: string
	cache?: string
    opts?: {
        width?: string | number
        height?: string | number
    }
}