import { useSettings } from './useSettings'

type Selector<T> = (settings: any) => T
type InferReturnType<T> = T extends Selector<infer R> ? R : never

export const useSettingsSelector = <T extends Selector<any>>(
	selector: T,
	fallback: InferReturnType<T> | undefined = undefined,
): InferReturnType<T> | undefined => {
	const { data: settings } = useSettings()

	if (typeof selector === 'function' && settings) {
		try {
			const result = selector(settings)
			if (result === undefined) {
				return fallback
			}
			return result
		} catch (error) {
			console.error('Invalid selector:', error)
			return fallback
		}
	}

	return fallback
}
