import { useState } from 'react'
import { useWindowEvent } from '@/hooks/use-window-event'
import styled from 'styled-components'

export const VersionLabel = () => {
  const [pressed, setPressed] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)

  useWindowEvent('keyup', (event) => {
    const hasCombination = event.altKey && event.code !== 'KeyV'
    if (hasCombination) return
    setPressed(false)
  })

  useWindowEvent('keydown', (event) => {
    if (event.altKey && event.code === 'KeyV') {
      event.preventDefault()
      if (pressed) return
      setPressed(true)
      setShow(!show)
    }
  })

  useWindowEvent('blur', () => {
    setShow(false)
  })

  if (!show) return null

  return (
    <LabelWrapper>
      <div>ver. {process.env.__APP_VERSION__}</div>
      {/*// @ts-ignore*/}
      <div>build: {__webpack_hash__}</div>
      <div>buildDate: {process.env.__BUILD_DATE__}</div>
    </LabelWrapper>
  )
}

const LabelWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  border-top-right-radius: 8px;
  padding: 4px 12px;
  font-size: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10001;
`
