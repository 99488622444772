import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
	FORCE_CLOSE_MODALS,
	OPEN_MODAL,
} from '@/components/shared/modal/modal-utils'
import ModalService from '@/components/shared/modal/ModalService'
import { AnimatePresence, motion } from 'framer-motion'
import { Portal } from 'react-portal'
import { easeQuadOut } from 'd3-ease'

// interface IModal {
//     component: React.ReactNode
//     props: any
//     close?: Function
// }

const ModalRoot = () => {
	const [modal, setModal] = useState<any>({})

	const closeAllmodals = () => {
		setModal({})
		const html = document.querySelector('html')
		if (html) {
			html.style.overflow = 'auto'
		}
	}

	const closeModalsOnEsc = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			closeAllmodals()
		}
	}

	useEffect(() => {
		ModalService.on(OPEN_MODAL, ({ component, props }) => {
			setModal({
				component,
				props,
				close: () => {
					setModal({})
				},
			})

			const html = document.querySelector('html')
			if (html) {
				html.style.overflow = 'hidden'
			}
		})

		document.addEventListener(FORCE_CLOSE_MODALS, closeAllmodals, true)
		document.addEventListener('keyup', closeModalsOnEsc, true)

		return () => {
			document.removeEventListener(FORCE_CLOSE_MODALS, closeAllmodals, true)
			document.removeEventListener('keyup', closeModalsOnEsc, true)
		}
	}, [])

	const renderModal = () => {
		const { backdrop = true, ...otherProps } = modal.props

		if (backdrop) {
			return (
				<ModalWrapper
					key="modal"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.2, ease: easeQuadOut }}
				>
					<ModalContent
						key="modalBody"
						initial={{ scale: 0.7 }}
						animate={{ scale: 1 }}
						exit={{ scale: 0.7 }}
						transition={{ duration: 0.2, ease: easeQuadOut }}
					>
						<modal.component {...otherProps} close={modal.close} />
					</ModalContent>
				</ModalWrapper>
			)
		}

		return (
			<ModalContent
				key="modalBody"
				initial={{ scale: 0.7 }}
				animate={{ scale: 1 }}
				exit={{ scale: 0.7 }}
				transition={{ duration: 0.2, ease: easeQuadOut }}
			>
				<modal.component {...otherProps} close={modal.close} />
			</ModalContent>
		)
	}

	return (
		<Portal>
			<AnimatePresence>{modal.component && renderModal()}</AnimatePresence>
		</Portal>
	)
}

export default ModalRoot

const ModalContent = styled(motion.div)`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100%;
	height: auto;
`
const ModalWrapper = styled(motion.div)`
	height: 100%;
	width: 100%;
	padding: 2rem;
	position: fixed;
	top: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 10001;
	background: rgba(0, 0, 0, 0.25);
`
