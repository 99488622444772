import React from 'react'
import styled, { css } from 'styled-components'

export const SearchPlacesIcon = ({ active, onClick, ...otherProps }) => {
    return (
        <SvgContainer $active={active} onClick={onClick} {...otherProps}>
            <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3588 9.12848C10.7739 8.8981 11.2631 8.84352 11.6915 8.97976L20.5005 11.7811C21.6643 12.1512 21.7698 13.6242 20.6818 14.3125L15.8354 17.3784C15.4046 17.651 14.8758 17.7257 14.415 17.5792L5.17453 14.6406C3.96819 14.257 3.91201 12.7059 5.08098 12.0572L10.3588 9.12848Z" fill="#2C2C2C" stroke="#FAFAFA" />
                <path d="M13.2426 13.3208C13.1516 13.2125 13.0465 13.0858 12.9305 12.9429C12.5168 12.4331 11.966 11.7199 11.4162 10.905C10.8654 10.0886 10.3217 9.17927 9.91733 8.27658C9.50999 7.36734 9.26074 6.50155 9.26074 5.76237C9.26074 3.56069 11.0409 1.78052 13.2426 1.78052C15.4443 1.78052 17.2245 3.56069 17.2245 5.76237C17.2245 6.50155 16.9752 7.36734 16.5679 8.27658C16.1635 9.17927 15.6198 10.0886 15.069 10.905C14.5192 11.7199 13.9684 12.4331 13.5547 12.9429C13.4387 13.0858 13.3336 13.2125 13.2426 13.3208ZM11.7572 7.24777C12.1512 7.64172 12.6855 7.86304 13.2426 7.86304C13.7997 7.86304 14.334 7.64172 14.728 7.24777C15.1219 6.85382 15.3433 6.31951 15.3433 5.76237C15.3433 5.20524 15.1219 4.67093 14.728 4.27698C14.334 3.88303 13.7997 3.66171 13.2426 3.66171C12.6855 3.66171 12.1512 3.88303 11.7572 4.27698C11.3633 4.67093 11.1419 5.20524 11.1419 5.76237C11.1419 6.31951 11.3633 6.85382 11.7572 7.24777Z" fill="#2C2C2C" stroke="#FAFAFA" />
            </svg>
        </SvgContainer>
    )
}

export const SearchUsersIcon = ({ active, onClick, ...otherProps }) => {
    return (
        <SvgContainer $active={active} onClick={onClick} {...otherProps}>
            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.531128 14.6596C0.531128 13.4673 1.68909 11.3335 5.60098 11.3335C9.51286 11.3335 10.6708 13.4673 10.6708 14.6596V16.4553H0.531128V14.6596Z" fill="#2C2C2C" stroke="#FAFAFA" />
                <path d="M8.23397 6.62477C8.23397 8.29592 7.01171 9.56826 5.60093 9.56826C4.19016 9.56826 2.9679 8.29592 2.9679 6.62477C2.9679 4.95361 4.19016 3.68127 5.60093 3.68127C7.01171 3.68127 8.23397 4.95361 8.23397 6.62477Z" fill="#2C2C2C" stroke="#FAFAFA" />
                <path d="M4.83264 14.1368C4.83264 12.596 6.32037 9.93945 11.1708 9.93945C16.0213 9.93945 17.509 12.596 17.509 14.1368V16.4552H4.83264V14.1368Z" fill="#2C2C2C" stroke="#FAFAFA" />
                <path d="M14.5173 4.27231C14.5173 6.37653 12.9756 7.99994 11.1708 7.99994C9.36602 7.99994 7.82434 6.37653 7.82434 4.27231C7.82434 2.16809 9.36602 0.544678 11.1708 0.544678C12.9756 0.544678 14.5173 2.16809 14.5173 4.27231Z" fill="#2C2C2C" stroke="#FAFAFA" />
            </svg>
        </SvgContainer>
    )
}

export const SearchLayersIcon = ({ active, onClick, ...otherProps }) => {
    return (
        <SvgContainer $active={active} onClick={onClick} {...otherProps}>
            <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.00397 8.30127C9.41912 8.07089 9.90825 8.01631 10.3367 8.15255L18.4689 10.7386C19.6327 11.1087 19.7382 12.5817 18.6502 13.27L14.2693 16.0416C13.8385 16.3141 13.3097 16.3888 12.8489 16.2423L4.32659 13.5322C3.12025 13.1485 3.06408 11.5974 4.23304 10.9488L9.00397 8.30127Z" fill="#2C2C2C" stroke="#FAFAFA" />
                <path d="M9.00384 5.57861C9.419 5.34823 9.90813 5.29365 10.3366 5.42989L18.4688 8.01599C19.6326 8.38607 19.7381 9.85907 18.6501 10.5474L14.2692 13.3189C13.8384 13.5914 13.3095 13.6662 12.8487 13.5196L4.32647 10.8095C3.12013 10.4259 3.06395 8.87479 4.23292 8.2261L9.00384 5.57861Z" fill="#2C2C2C" stroke="#FAFAFA" />
                <path d="M9.00384 2.87634C9.419 2.64596 9.90813 2.59138 10.3366 2.72762L18.4688 5.31372C19.6326 5.6838 19.7381 7.1568 18.6501 7.8451L14.2692 10.6166C13.8384 10.8892 13.3095 10.9639 12.8487 10.8174L4.32647 8.10723C3.12013 7.72361 3.06395 6.17252 4.23292 5.52383L9.00384 2.87634Z" fill="#2C2C2C" stroke="#FAFAFA" />
            </svg>
        </SvgContainer>
    )
}

export const SearchHashIcon = ({ active, onClick, ...otherProps }) => {
    return (
        <SvgContainer $active={active} onClick={onClick} {...otherProps}>
            <svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.43787 18.9063C1.36982 18.9063 0.500488 19.7752 0.500488 20.8437C0.500488 21.9123 1.36981 22.7812 2.43787 22.7812H7.42871L6.27869 29.2216C6.18787 29.731 6.30042 30.2451 6.5965 30.6699C6.89264 31.0946 7.33631 31.3784 7.8443 31.4693C7.95874 31.4901 8.07416 31.5 8.18769 31.5C9.12617 31.5 9.92744 30.8283 10.093 29.9027L11.3647 22.7812H18.2132L17.0631 29.2216C16.9723 29.731 17.0849 30.2451 17.381 30.6699C17.6771 31.0946 18.1208 31.3784 18.6288 31.4693C18.7432 31.4901 18.8586 31.5 18.9722 31.5C19.9106 31.5 20.7119 30.8283 20.8774 29.9027L22.1492 22.7812H29.5618C30.6298 22.7812 31.4991 21.9123 31.4991 20.8437C31.4991 19.7752 30.6298 18.9063 29.5618 18.9063H22.8411L23.879 13.0941H29.5618C30.6298 13.0941 31.4991 12.2251 31.4991 11.1566C31.4991 10.0881 30.6298 9.21919 29.5618 9.21919H24.5709L25.7209 2.77882C25.8118 2.26936 25.6992 1.7552 25.4031 1.33046C25.107 0.905723 24.6633 0.621903 24.1544 0.531083C23.1043 0.345663 22.0949 1.04616 21.9067 2.09767L20.6349 9.21919H13.7864L14.9365 2.77882C15.0273 2.26936 14.9147 1.7552 14.6187 1.33046C14.3226 0.905723 13.8788 0.621903 13.3699 0.531083C12.3085 0.340473 11.3105 1.04664 11.1222 2.09767L9.85047 9.2192H2.43787C1.36982 9.2192 0.500488 10.0881 0.500488 11.1566C0.500488 12.2251 1.36981 13.0941 2.43787 13.0941H9.15857L8.12067 18.9063L2.43787 18.9063ZM13.0945 13.0941H19.9431L18.9051 18.9063H12.0566L13.0945 13.0941Z" fill="#2C2C2C" />
            </svg>
        </SvgContainer>
    )
}


export const SearchBookingsIcon = ({ active, onClick, ...otherProps }) => {
    return (
        <SvgContainer $active={active} onClick={onClick} {...otherProps}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_486_732)">
                    <path d="M4.5 0.5625C4.5 0.413316 4.44074 0.270242 4.33525 0.164752C4.22976 0.0592632 4.08668 0 3.9375 0C3.78832 0 3.64524 0.0592632 3.53975 0.164752C3.43426 0.270242 3.375 0.413316 3.375 0.5625V1.125H2.25C1.65326 1.125 1.08097 1.36205 0.65901 1.78401C0.237053 2.20597 0 2.77826 0 3.375L0 4.5H18V3.375C18 2.77826 17.7629 2.20597 17.341 1.78401C16.919 1.36205 16.3467 1.125 15.75 1.125H14.625V0.5625C14.625 0.413316 14.5657 0.270242 14.4602 0.164752C14.3548 0.0592632 14.2117 0 14.0625 0C13.9133 0 13.7702 0.0592632 13.6648 0.164752C13.5593 0.270242 13.5 0.413316 13.5 0.5625V1.125H4.5V0.5625ZM18 15.75V5.625H0V15.75C0 16.3467 0.237053 16.919 0.65901 17.341C1.08097 17.7629 1.65326 18 2.25 18H15.75C16.3467 18 16.919 17.7629 17.341 17.341C17.7629 16.919 18 16.3467 18 15.75ZM12.2108 9.96075L8.83575 13.3358C8.7835 13.3881 8.72143 13.4297 8.65309 13.4581C8.58475 13.4864 8.51149 13.501 8.4375 13.501C8.36351 13.501 8.29025 13.4864 8.22191 13.4581C8.15357 13.4297 8.0915 13.3881 8.03925 13.3358L6.35175 11.6483C6.24613 11.5426 6.18679 11.3994 6.18679 11.25C6.18679 11.1006 6.24613 10.9574 6.35175 10.8517C6.45737 10.7461 6.60063 10.6868 6.75 10.6868C6.89937 10.6868 7.04263 10.7461 7.14825 10.8517L8.4375 12.1421L11.4142 9.16425C11.5199 9.05863 11.6631 8.99929 11.8125 8.99929C11.9619 8.99929 12.1051 9.05863 12.2108 9.16425C12.3164 9.26987 12.3757 9.41313 12.3757 9.5625C12.3757 9.71187 12.3164 9.85513 12.2108 9.96075Z" fill="#2C2C2C" />
                </g>
                <defs>
                    <clipPath id="clip0_486_732">
                        <rect width="18" height="18" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgContainer>
    )
}

const SvgContainer = styled.div<{ $active?: boolean }>`
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    ${({ $active }) => $active ? css`
        opacity: 1 !important;
        fill: #2C2C2C;
    ` : css`
        opacity: 0.5;
        fill: #838D96;
    `}
`