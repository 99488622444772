import React from 'react'
import Grid from '@/ui/components/Grid'
import { useFormikContext } from 'formik'
import BookingGridSelector from './BookingGridSelector'
import { ErrorBoundary } from 'react-error-boundary'
import { translate } from '@/i18n'

const BookingGridField = ({ bookings, addItem }) => {
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' })
  const { values } = useFormikContext<any>()

  // if (values.type === '3' || !isBigScreen) return null
  // if (values.type === '3') return null

  return (
    <ErrorBoundary
      fallback={<div>{translate('calendar-initialize-error')}</div>}
    >
      <Grid container>
        <Grid item xs={12} md={12}>
          <BookingGridSelector bookings={bookings} addItem={addItem} />
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}

export default BookingGridField
