import React, { useEffect, useMemo, useState } from 'react'
import Grid from '@/components/Grid'
import { Input } from '@/ui/components/Field/Input'
import EmployeeListItem, { EmployeeMobileListItem } from './EmployeeListItem'
import Toolbar from '../Toolbar/Toolbar'
import {
  makeEmployeeData,
  makeEmployeeMobileData,
  UserExtra
} from './makeEmployeeData'
import Pagination from '@/ui/components/Pagination/Pagination'
import { useUsers } from '@/api/hooks/useUsers'
import { debounce } from 'lodash'
import { useUsersListerFields } from '@/api/hooks/useUsersListerFields'
import { RuntimeFieldData } from '@/api/services/user.service'
import Sidebar from '../layout/Sidebar/Sidebar'
import useResponsive from '@/hooks/useResponsive'
import styled, { css } from 'styled-components'
import useSorting from '@/hooks/useSorting'
import { translate } from '@/i18n'
import { useIntl } from 'react-intl'

export const makeCols = (fields: RuntimeFieldData[] | undefined) => {
  if (!fields) return []

  const extra: UserExtra[] = fields.map((field) => ({
    uid: field.uid,
    accessor: field.alias,
    label: field.label,
    type: field.type,
    alias: field.alias
  }))

  return extra.filter((col) => col.alias != 'extensionattribute15' && !col.alias.startsWith('#'))
}

export const makeColumns = (
  fields: RuntimeFieldData[] | undefined,
  initialCols: any = []
) => {
  const cols = initialCols.concat()

  if (!fields) return []

  const extra: UserExtra[] = fields.map((field) => ({
    uid: field.uid,
    accessor: field.alias,
    label: field.label,
    type: field.type,
    alias: field.alias
  }))

  return cols.concat(extra)
}

const Employees = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filter, setFilter] = useState<string>('')
  const { sort, direction, handleSort } = useSorting()

  const { data } = useUsers({
    page: currentPage,
    perPage: 20,
    name: filter,
    sort,
    direction
  })
  const { data: extra } = useUsersListerFields()
  const { isDesktop } = useResponsive()
  const intl = useIntl()

  const handleChange = (e) => setFilter(e?.target?.value)
  const debouncedResponse = useMemo(() => {
    return debounce(handleChange, 500)
  }, [])

  useEffect(() => {
    return () => debouncedResponse.cancel()
  }, [])

  const columns = makeCols(extra?.fields)
  const users = makeEmployeeData(data?.items, columns)
  const template = `40px 1fr ${columns.map(() => `1fr`).join(' ')} 40px`
  const dafta = makeEmployeeMobileData(data?.items, columns)

  return (
    <Sidebar minWidth={isDesktop ? 1024 : 1}>
      <Sidebar.Header title="employees" />

      <Toolbar>
        <Toolbar.Item xs={12} md={4}>
          <Toolbar.BlackLabel>
            {translate('search-by-userdata')}
          </Toolbar.BlackLabel>
          <Input
            $fullWidth
            placeholder={intl.formatMessage({ id: 'search' })}
            onChange={debouncedResponse}
          />
        </Toolbar.Item>
      </Toolbar>
      <Grid>
        {isDesktop && (
          <Grid.RowHeader $cols={template}>
            <TableCell
              onClick={handleSort.bind(null, 'id')}
              $active={sort === 'id'}
              $direction={direction}
            >
              id
            </TableCell>
            <TableCell
              onClick={handleSort.bind(null, 'display')}
              $active={sort === 'display'}
              $direction={direction}
            >
              {translate('full-name')}
            </TableCell>
            {columns.map((column) => (
              <TableCell
                key={column.uid}
                onClick={handleSort.bind(null, column.uid)}
                $active={sort === column.uid}
                $direction={direction}
              >
                {column.label}
              </TableCell>
            ))}
            <Grid.Item />
          </Grid.RowHeader>
        )}
        {isDesktop &&
          users.map((user) => (
            <EmployeeListItem
              key={user.id}
              columns={columns}
              template={template}
              item={user}
            />
          ))}

        {!isDesktop &&
          dafta.map((user) => (
            <EmployeeMobileListItem key={user.id} user={user} />
          ))}
      </Grid>

      <Pagination
        currentPage={currentPage}
        total={data?.total || 0}
        handlePageChange={setCurrentPage}
      />
    </Sidebar>
  )
}

export default Employees

export const TableCell = styled(Grid.Item)<{
  $active?: boolean
  $direction?: number
}>`
  cursor: pointer;
  position: relative;
  padding-right: 20px;

  &::after {
    opacity: 0;
    content: ' ';
    position: absolute;
    top: 50%;
    right: 5px;
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='6' width='1.41421' height='8.48527' transform='rotate(45 6 0)' fill='%23fff' /%3E%3Crect x='7' y='11' width='1.41421' height='8.48527' transform='rotate(135 7 11)' fill='%23fff' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: translateY(-50%) rotate(-90deg);
    height: 12px;
    width: 7px;

    ${({ $active }) =>
      $active &&
      css`
        opacity: 1;
      `}

    ${({ $direction }) =>
      $direction &&
      css`
        transform: translateY(-50%) rotate(90deg);
      `}
  }
`
