import { NodeElement } from '@/api/project';
import { Grid } from '@/components/Grid';
import useResponsive from '@/hooks/useResponsive';
import { translate } from '@/i18n';
import { useGlobalStore } from '@/stores/globalStore';
import { useMapStore } from '@/stores/mapStore';
import { useProjectStore } from '@/stores/projectStore';
import React from 'react';
import EmptySeatsMobileElement from './EmptySeatsMobileElement';

type ElementsListItemProps = {
    item: NodeElement
}

const EmptySeatsElement: React.FC<ElementsListItemProps> = ({ item }) => {
    const setSeat = useGlobalStore(state => state.setSeat)
    const setSeatEmployee = useGlobalStore(state => state.setSeatEmployee)
    const setActiveLayer = useGlobalStore(state => state.setActiveLayer)
    const setZoomSeat = useMapStore(state => state.setZoomSeat)
    const { isDesktop } = useResponsive()

    const handleSeatView = (e) => {
        e.preventDefault()
        
        setActiveLayer(Number(item.parent))
        setSeatEmployee(null)
        setSeat(Number(item.id))
        setZoomSeat({ seat: Number(item.id), parent: Number(item.parent) })
    }

    const handleSeatChange = (e) => {
        e.preventDefault()
        setSeatEmployee(null)
        setSeat(Number(item.id))
    }

    if (!isDesktop) return (
        <EmptySeatsMobileElement
            name={item.name}
            item={item}
            handleNavigate={handleSeatView}
            openSeat={handleSeatChange}
        />
    )

    return (
        <Grid.Row $cols="2fr 1fr 1fr">
            <Grid.Item>{item.name}</Grid.Item>
            <Grid.Item>
                <Place item={item} />
            </Grid.Item>
            <Grid.Item>{item.type_name}</Grid.Item>
        </Grid.Row>
    )
}

export default EmptySeatsElement

export const Place = ({ item }) => {
    const nodes = useProjectStore(state => state.nodes)
    const place = nodes.find(tree => tree.id == item.parent)

    return (
        <div>{place ? place.name : translate('not-specified')}</div>
    )
}
