export class BomjMapper {
  public static getColumns(report = []) {
    if (!report.length) {
      return []
    }
    return Object.keys(report[0]).filter(
      (v) => !['id', 'enabled', 'login', 'role', 'upd'].includes(v)
    )
  }

  public static getTranslations(columns, userProps) {
    const translations = {}

    userProps
      .filter((v) => !['extensionattribute15'].includes(v.alias))
      .forEach((prop) => {
        if (columns.includes(prop.key)) {
          translations[prop.key] = prop.label
        }
      })

    return translations
  }

  public static getReportColumns(report, userProps) {
    if (!report.length || !userProps.length) {
      return { columns: [], translations: [] }
    }

    const extension = userProps
      .filter((prop) => ['extensionattribute15'].includes(prop.alias))
      .map((v) => v.key)

    const columns = Object.keys(report[0])
      .filter((v) => !['id', 'enabled', 'login', 'role', 'upd'].includes(v))
      .filter((v) => !extension.includes(v))

    const translations = {}

    userProps
      .filter((v) => !['extensionattribute15'].includes(v.alias))
      .forEach((prop) => {
        if (columns.includes(prop.key)) {
          translations[prop.key] = prop.label
        }
      })

    return { columns, translations }
  }
}
