import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import { useQuery } from 'react-query'
import { BookingService } from '../services/booking.service'

export type MomentType =
	| 'all'
	| 'past'
	| 'current'
	| 'future'
	| 'recurrent'
	| 'constant'
	| 'my'
	| 'common'
	
type UseBookingsProps = {
	page: number
	perPage?: number
	moment?: MomentType
	name?: string
	placeType?: string
	place?: string
	bookingType?: string
	sort?: string
	direction?: 0 | 1
	day?: Date
	start?: Date
	end?: Date
	my?: string
}

export const useBookings = ({
	page,
	perPage,
	moment,
	bookingType,
	placeType,
	name,
	sort,
	direction,
	day,
	start,
	end,
	place,
	my,
}: UseBookingsProps) => {
	const { workspaceId, projectId } = useProject()
	const { enqueueToast } = useToast()

	const { data, isLoading, refetch } = useQuery(
		[
			'bookings',
			workspaceId,
			projectId,
			page,
			moment,
			bookingType,
			placeType,
			name,
			sort,
			direction,
			day,
			start,
			end,
			place,
		],
		() =>
			BookingService.getBookingList({
				workspaceId,
				projectId,
				page: page - 1,
				perPage,
				moment,
				bookingType,
				placeType,
				user: name,
				sort,
				direction,
				day,
				start,
				end,
				place,
				my,
			}),
		{
			enabled: !!workspaceId && !!projectId && !!page,
			select: ({ data }) => ({ items: data.items, total: data.total }),
			keepPreviousData: true,
			onError: () => {
				enqueueToast(
					{
						title: 'Ошибка!',
						message: 'Не удалось загрузить список бронирований',
					},
					{ variant: 'error' },
				)
			},
		},
	)

	return { data, isLoading, refetch }
}
