import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useParallelBooking } from '@/api/hooks/useParallelBooking'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useBooking } from '@/api/hooks/useBooking'
import { useBookingDialogStore } from '../../BookingModal'
import { convertToPeriods } from '@/api/bookings'
import {
	areIntervalsOverlapping,
	endOfWeek,
	startOfWeek,
	isAfter,
	eachWeekOfInterval,
	setWeek,
	getWeek,
	addWeeks,
	addMinutes,
	isValid,
	format,
	addDays,
} from 'date-fns'
import { useBookingStore } from '@/stores/bookingStore'
import {
	extractGaps,
	generateGap,
	isDateInRange,
	formatToISOTimezone,
} from '@/utils/helpers/dates.helpers'
import { bookingInterval } from '@/utils/constants/booking.constants'
import media from '@/ui/media'
import useResponsive from '@/hooks/useResponsive'
import { translate } from '@/i18n'
import CalendarTooltip from '@/components/shared/booking/form/tooltip/CalendarTooltip'
import { useMapStore } from '@/stores/mapStore'
import { times } from 'lodash'
import { is } from 'date-fns/locale'
import { uniqArray } from '@/utils/helpers/array.helpers'

export type BookingSlot = {
	start: Date
	end: Date
}

type GridCalendarProps = {
	addItem: Function
}

const getActualGaps = (slots, weekStart, weekEnd) => {
	if (!slots) return []

	return slots
		.concat()
		.filter((v) => v.start && v.end)
		.map((slot) => {
			const isSlotAfter = isAfter(slot.start, slot.end)
			return isSlotAfter
				? {
						start: slot.end,
						end: slot.start,
				  }
				: slot
		})
		.filter((slot) =>
			areIntervalsOverlapping(
				{ start: slot.start, end: slot.end },
				{ start: weekStart, end: weekEnd },
			),
		)
		.map((slot) => ({
			start: addMinutes(slot.start, -180),
			end: addMinutes(slot.end, -180),
		}))
}

const GridCalendar: React.FC<GridCalendarProps> = () => {
	const bookingId = useBookingDialogStore((state) => state.bookingId)
	const week = useBookingStore((state) => state.week)

	const weekStart = startOfWeek(week, { weekStartsOn: 1 })
	const weekEnd = endOfWeek(week, { weekStartsOn: 1 })

	const { isMobile } = useResponsive()
	const { values, setFieldValue } = useFormikContext<any>()
	const { data: current } = useBooking(bookingId)
	const { data, isLoading } = useParallelBooking({
		nodeId: values.seat.id,
		userId: values.user.id,
		weekStart,
		weekEnd,
	})

	// grid state
	const [selection, setSelection] = useState<any>({
		current: [],
		own: [],
		foreign: [],
		parallel: [],
	})

	const [drag, setDrag] = useState<boolean>(false)
	const [deselect, setDeselect] = useState<boolean>(false)
	const [start, setStart] = useState<number | null>(null)
	const [end, setEnd] = useState<number | null>(null)

	const startBook =
		values.start || weekStart || startOfWeek(new Date(), { weekStartsOn: 1 })
	const endBook =
		values.type === '3'
			? addWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1)
			: values.end || weekEnd || endOfWeek(new Date(), { weekStartsOn: 1 })

	const slots = values.dates
	const type = values.type
	const seat = values.seat.id

	// current booking slots
	const bookSlots =
		current?.slots.reduce(
			(acc, slot) =>
				acc.concat(convertToPeriods(slot.start, slot.end), weekStart),
			[],
		) || []

	useEffect(() => {
		let otherSlots
		let parallelSlots

		// if (!data) return

		if (!data || !data.slots) {
			otherSlots = []
			parallelSlots = []
		} else {
			otherSlots = data.slots
			parallelSlots = data.parallel
		}

		const predicate = (slot) => slot.user === Number(values.user.id)
		const weekStart = startOfWeek(week, { weekStartsOn: 1 })
		const weekEnd = endOfWeek(week, { weekStartsOn: 1 })

		let filteredByDate = slots

		if (type != '1') {
			filteredByDate = slots
				.filter((slot) => isValid(slot.start) && isValid(slot.end))
				.filter((slot) => slot.start && slot.end)
				.filter((slot) =>
					areIntervalsOverlapping(
						{ start: slot.start, end: slot.end },
						{ start: startBook, end: endBook },
					),
				)
		}

		const newSlots = {
			current: filteredByDate,
			own: getActualGaps(otherSlots.filter(predicate), weekStart, weekEnd),
			foreign: getActualGaps(
				otherSlots.filter((slot) => !predicate(slot)),
				weekStart,
				weekEnd,
			),
			parallel: getActualGaps(parallelSlots, weekStart, weekEnd),
		}

		// const currentGaps = newSlots.current.reduce(
		//   (acc, slot) =>
		//     slot.start && slot.end
		//       ? acc.concat(
		//           convertToPeriods(
		//             slot.start,
		//             slot.end,
		//             startOfWeek(slot.start, { weekStartsOn: 1 })
		//           )
		//         )
		//       : acc,
		//   []
		// )

		const currentGaps = newSlots.current.reduce(
			(acc, slot) =>
				slot.start && slot.end
					? acc.concat(convertToPeriods(slot.start, slot.end, weekStart))
					: acc,
			[],
		)

		const gaps = {
			current: currentGaps.filter((v) => v >= 0 && v <= 355),
			own: newSlots.own
				.reduce(
					(acc, slot) =>
						acc.concat(convertToPeriods(slot.start, slot.end, weekStart)),
					[],
				)
				.filter((v) => !bookSlots.includes(v))
				.filter((v) => v >= 0 && v <= 355),
			foreign: newSlots.foreign
				.reduce(
					(acc, slot) =>
						acc.concat(convertToPeriods(slot.start, slot.end, weekStart)),
					[],
				)
				.filter((v) => v >= 0 && v <= 355),
			parallel: newSlots.parallel
				.reduce(
					(acc, slot) =>
						acc.concat(convertToPeriods(slot.start, slot.end, weekStart)),
					[],
				)
				.filter((v) => v >= 0 && v <= 355),
		}

		console.log('newSlots', newSlots)

		setSelection(gaps)
	}, [slots, week, seat, isLoading, isMobile])

	// useEffect(() => {
	//     setFieldValue('gaps', [...selection.current])
	// }, [selection.current])

	const isFilled = (id) => {
		if (!start || !end) return false
		const arr = [start, end].sort((a, b) => a - b)
		return id >= arr[0] && id <= arr[1]
	}

	function dragStartHandler(e) {
		if (type === '3' || isMobile) return
		const id = e.target.dataset.id
		const { own, current, foreign, parallel } = selection

		const isSelected = current.includes(Number(id))

		if (isSelected) {
			setDeselect(true)
		}

		const isMyBooking = own.includes(Number(id))
		const isOtherBooking = foreign.includes(Number(id))
		const isParallelBooking = parallel.includes(Number(id))

		const isExist = isMyBooking || isOtherBooking || isParallelBooking

		if (id && !isExist) {
			setDrag(true)
			setStart(id)
			setEnd(id)
		}
	}

	function dragOverHandler(e) {
		if (type === '3' || isMobile) return
		if (!drag) return

		const id = e.target.dataset.id
		if (id) {
			setEnd(id)
		}
	}

	function dragLeaveHandler(e) {
		if (type === '3' || isMobile) return
		setDrag(false)

		if (start && end) {
			const arr = [start, end].sort((a, b) => a - b)
			setStart(null)
			setEnd(null)
			let revalidation

			if (deselect) {
				const gap = generateGap(arr)
				revalidation = selection.current.filter((cell) => !gap.includes(cell))
			} else {
				const gap = generateGap(arr)
				const { current, foreign } = selection
				const filtered = gap.filter(
					(item) => !(current.includes(item) || foreign.includes(item)),
				)
				revalidation = selection.current.concat(filtered)
			}

			const newRevalidation = uniqArray(
				revalidation.filter((v) => v >= 0 && v <= 335),
			).sort((a, b) => a - b)

			revalidate(newRevalidation)
		}

		setDeselect(false)
	}

	const revalidate = (revalidate) => {
		let timeSlots
		// const activeSlots = slots.filter(slot => !isDateInRange({ start: slot.start, end: slot.end}, { start: weekStart, end: weekEnd }))

		if (type == '2' && startBook && endBook) {
			const valid = revalidate.filter((v) => v >= 0 && v <= 355)
			const newSlots = extractGaps(valid.sort((a, b) => a - b))

			const weeks = eachWeekOfInterval(
				{ start: startBook, end: endBook },
				{ weekStartsOn: 1 },
			)

			timeSlots = weeks.reduce((acc, week) => {
				const slotsFromWeek: Array<{ start: Date; end: Date }> = []

				newSlots.forEach((slot) => {
					const startDate = new Date(week.getTime() + bookingInterval * slot[0])
					const endDate = new Date(
						week.getTime() +
							bookingInterval * Number(slot[1]) +
							bookingInterval,
					)

					slotsFromWeek.push({
						start: addMinutes(startDate, -180),
						end: addMinutes(endDate, -180),
					})
				})

				return [...acc, ...slotsFromWeek]
			}, [] as Array<{ start: Date; end: Date }>)
		} else {
			const newSlots = extractGaps(revalidate.sort((a, b) => a - b))

			timeSlots = newSlots.map((slot) => ({
				start: addMinutes(
					new Date(weekStart.getTime() + 30 * 60 * 1000 * slot[0]),
					-180,
				),
				end: addMinutes(
					new Date(
						weekStart.getTime() +
							bookingInterval * Number(slot[1]) +
							bookingInterval,
					),
					-180,
				),
			}))
		}

		setFieldValue('dates', [...timeSlots])
	}

	const setTooltip = useMapStore((state) => state.setTooltip)

	const onGroupMouseEnterHandler = React.useCallback(
		(e) => {
			const id = e.target.dataset.id

			// if (!data && !id) {
			//     return
			// }
			if (data && id) {
				const parallelBookings = data.slots
				const slot = parallelBookings
					.map((item) => ({
						...item,
						gaps: convertToPeriods(item.start, item.end, weekStart).filter(
							(v) => v >= 0 && v <= 355,
						),
					}))
					.find((item) => item.gaps.includes(Number(id)))

				if (slot) {
					setTooltip(slot.display)
				}
			}
		},
		[setTooltip, data],
	)

	const onGroupMouseLeaveHandler = React.useCallback(
		(e) => {
			setTooltip(null)
		},
		[setTooltip],
	)

	return (
		<CalendarWrapper>
			<GridDays>
				<GridDay>
					{translate('monday')} {format(weekStart, 'dd.MM')}
				</GridDay>
				<GridDay>
					{translate('tuesday')} {format(addDays(weekStart, 1), 'dd.MM')}
				</GridDay>
				<GridDay>
					{translate('wensday')} {format(addDays(weekStart, 2), 'dd.MM')}
				</GridDay>
				<GridDay>
					{translate('thursday')} {format(addDays(weekStart, 3), 'dd.MM')}
				</GridDay>
				<GridDay>
					{translate('friday')} {format(addDays(weekStart, 4), 'dd.MM')}
				</GridDay>
				<GridDay>
					{translate('saturday')} {format(addDays(weekStart, 5), 'dd.MM')}
				</GridDay>
				<GridDay>
					{translate('sunday')} {format(addDays(weekStart, 6), 'dd.MM')}
				</GridDay>
			</GridDays>

			<Calendar
				onMouseDown={(e) => dragStartHandler(e)}
				onMouseUp={(e) => dragLeaveHandler(e)}
				onMouseMove={(e) => dragOverHandler(e)}
				id="book-form-calendar"
			>
				{Array.from({ length: 48 * 7 }).map((item, idx) => {
					const filled = isFilled(idx)
					const isMyBooking = selection.own.map((item) => item).includes(idx)
					const isSelected = selection.current.includes(idx) || filled
					const isOtherBooking = selection.foreign
						.map((item) => item)
						.includes(idx)
					const isParallelBooking = selection.parallel
						.map((item) => item)
						.includes(idx)
					const isGapFilled =
						isMyBooking || isSelected || isOtherBooking || isParallelBooking
					const color = isSelected
						? '#d2233c'
						: isOtherBooking
						? '#FD4F26'
						: isParallelBooking
						? '#c795f1'
						: '#F3BB5E'

					return (
						<GridItem
							key={idx}
							// data-time={idx * 30 * 60 * 1000 + week.getTime()}
							data-type={true}
							data-id={idx}
							$color={deselect && filled ? 'rgba(0,0,0,0.25)' : color}
							$filled={isGapFilled}
							$hoverable={isMobile}
							onMouseEnter={onGroupMouseEnterHandler}
							onMouseLeave={onGroupMouseLeaveHandler}
							// $filled={isFilled(idx)}
						/>
					)
				})}
			</Calendar>

			<CalendarTooltip />
		</CalendarWrapper>
	)
}

export default GridCalendar

const GridDays = styled.div`
	display: grid;
	row-gap: 6px;
	width: 42px;
	grid-template-rows: repeat(7, 1fr);
`

const CalendarWrapper = styled.div`
	display: grid;
	grid-template-columns: 48px 1fr;
	position: relative;
`

const Calendar = styled.div`
	display: grid;
	row-gap: 6px;
	grid-template-columns: repeat(48, 1fr);
	z-index: 11;
`

const GridDay = styled.div`
	font-size: 1rem;
	line-height: 1rem;
	color: #000000;
	display: flex;
	align-items: center;
	text-transform: capitalize;
`

const GridItem = styled.div<{
	$filled?: boolean
	$color?: string
	$hoverable?: boolean
}>`
	max-width: 13px;
	height: 13px;
	background: rgba(0, 0, 0, 0.25);
	border-radius: 2px;
	cursor: pointer;
	user-select: none;
	transition: transform 0.3s;

	${media.lg`
        max-width: 20px;
        border-radius: 0px;
    `}

	&:hover {
		transform: scale(1.5);
	}

	${({ $filled, $color }) =>
		$filled &&
		$color &&
		css`
			background: ${$color};
		`}

	${({ $hoverable }) =>
		$hoverable &&
		css`
			cursor: default;

			&:hover {
				transform: none;
			}
		`}
`
