import { LOCALES } from './../locales'

export default {
	[LOCALES.ENGLISH]: {
		'log-in': 'Log In',
		'log-out': 'Log out',

		search: 'Search',
		loading: 'Loading',
		load: 'load',
		'no-results': 'No results',
		'select-period': 'Select period',
		profile: 'Profile',
		'stop-book': 'Stop booking',
		'stop-book-error': "Couldn't stop booking",
		'stop-book-success': 'Booking stopped',

		'start-time': 'Start time',
		'end-time': 'End time',
		'start-date': 'Start date',
		'end-date': 'End date',
		'brand-name': 'Office Map',
		bookings: 'Bookings',
		employees: 'Employees',
		objects: 'Objects',
		reports: 'Reports',
		levels: 'Levels',
		info: 'Info',
		close: 'Close',
		delete: 'Delete',
		cancel: 'Cancel',
		confirm: 'Confirm',

		'internal-add-error': 'Internal error while booking',

		place: 'Object',
		booking: 'Booking',
		type: 'Type',
		position: 'Position',
		description: 'Description',
		'all-bookings': 'All bokings',
		'past-bookings': 'Last',
		'current-bookings': 'Current',
		'future-bookings': 'Future',
		'constant-bookings': 'Permanent',
		'weekly-bookings': 'Weekly',
		'full-name': 'Full name',
		'common-bookings': 'Common',
		location: 'Location',
		date: 'Date',
		common: 'Common',
		recurrent: 'Weekly',
		constant: 'Permanent',
		success: 'Success',
		error: 'Error',
		'booking-deleted': 'Booking deleted',
		'failed-to-delete-booking': 'Failed to delete booking',
		'delete-booking': 'Delete booking?',
		'delete-booking-confirm': 'Are you sure you want to delete the booking?',
		'print-layer': 'Print',
		'printing-layer': 'Printing...',

		'auth_auth-text': 'Login',
		auth_login: 'Login',
		auth_password: 'Password',
		'appbar_datepicker-title': 'Bookings on the map',
		'search-bookings-by-user-or-object': 'Search by Employee or Object',
		'object-type': 'Object type',
		'date-display-error': 'Date display error',
		'search-by-name': 'Search by name',
		'all-levels': 'All levels',
		all: 'All',
		service: 'Service',
		'about-object': 'Object',
		'object-types': 'Type',
		'object-name': 'Object',
		'show-on-map': 'Show on map',
		more: 'More',
		'search-by-userdata': 'Search',
		booking_category: "Booking's category",
		'about-employee': 'Employee',
		'not-authorized': 'Not authorized',
		'booking-added': 'Booking added',
		'booking-added-confirm': 'Your booking was added successfully',
		'allowed-for-authorized': 'Allowed only for authorized users',
		'booking-type': 'Booking type',
		'choose-location': 'Select Location',
		'select-location': 'Select Location',
		'select-object': 'Select Object',
		'choose-employee': 'Select Employee',
		'select-employee': 'Select Employee',
		'start-datetime': 'Start time and date',
		'end-datetime': 'End time and date',
		'already-booked': 'Another employee',
		'booked-by-me': 'Another bookings',
		'my-parallel-bookings': 'Parallel bookings',
		'current-booking': 'Current',
		free: 'Free',
		show: 'Show',
		hide: 'Hide',
		'no-parallel-bookings': 'No Parallel bookings',
		'bookings-for-period': 'Bookings for period',
		'show-bookings-between': '{start} - {end}',
		'calendar-initialize-error': 'Failed to initialize calendar',
		select: 'Select',
		'add-interval': 'Add interval',
		'week-days': 'Days of the week',
		monday: 'mo',
		tuesday: 'tu',
		wensday: 'we',
		thursday: 'th',
		friday: 'fr',
		saturday: 'sa',
		sunday: 'su',
		'start-working': 'Start of work',
		'end-working': 'End of work',
		save: 'Save',
		submit: 'Submit',
		resolver_state_1: 'Current booking overlaps with an existing booking',
		resolver_state_2: "Current booking overlaps with someone else's booking",
		resolver_state_4: 'Unable to create a booking for this property',
		resolver_state_8: 'You cannot create new booking for this object type',
		resolver_state_10: 'Booking object not found',
		'bookings-report': 'List of bookings for the selected day',
		'place-report': 'Workplace load',
		'square-report': 'Area report',
		'report-template': 'Report: "{name}"',
		'free-places-v1': 'Free places for the period',
		'non-free-places-v1': 'Non free places for the period',
		'no-place-users': 'Not booked users',
		'inventory-report': 'Inventory',

		'report-full-name': 'Full name',
		'report-object': 'Object',
		'report-booking-type': 'Booking type',
		'report-date': 'Date',
		'report-place-name': 'Object',
		'report-laod': 'load',

		'report-type_name': 'Object type',
		'report-name': 'Object',
		'report-parent': 'Position',

		'not-specified': 'Not specified',

		'add-report': 'Add report',
		'create-report': 'Add report',
		'select-report': 'Select report',
		'select-colummns': 'Select colummns',
		user: 'Employee',
		'save-pdf': 'Save as PDF',
		'save-csv': 'Save as CSV',
		'select-data-to-display': 'Select data to display',
		'place-name': 'Object',
		name: 'Name',

		'create-report-bookings': 'Bookings report',
		'create-report-employees': 'Employees report',
		'create-report-objects': 'Objects Report',
		object: 'Object',

		book: 'Book',
		'project-not-found': 'Project not found',
		'go-to-test-project': 'Go to test project',
		employee: 'Employee',
		'object-service': 'Object service',
		'service-type': 'Service type',
		cleaning: 'Cleaning',
		maintenance: 'Maintenance',
		'more-info': 'More info',
		describe: 'Description',
		send: 'Send',
		'service-submitted': 'Application accepted for processing',

		'error-load-metadata': 'Error while loading metadata',

		edit: 'Edit',
		'map-loading': 'Loading map',
		'data-loading': 'Loading data',

		'my-bookings': 'My bookings',
		'search-bookings-by-user': 'Search by user',
		'search-bookings-by-object': 'Search by object',
		'report-area-cabinet-name': 'Cabinet name',
		'report-area-parent-name': 'Parent name',
		'report-area-arender-name': 'Name of tenant',
		'report-area-square': 'area, sq.m',
		'report-area-places': 'Seats q-ty in the office',

		'stop-book': 'Stop booking',
	},
}
