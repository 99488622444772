import { api, ApiResponseType } from '@/api'
import { MomentType } from '@/api/hooks/useBookings'
import {
	formatLocalDateToAPI,
	formatToAPI,
} from '@/utils/helpers/dates.helpers'
import {
	addYears,
	eachDayOfInterval,
	endOfDay,
	format,
	getDay,
	getHours,
	getMinutes,
	startOfDay,
	subYears,
} from 'date-fns'

const getCurrentGaps = () => {
	const now = new Date()
	const num = getDay(now)
	const hours = getHours(now)
	const minutes = getMinutes(now)

	const minutesGap = minutes % 30 > 0 ? 2 : 1
	const gaps = hours * 2 + minutesGap

	const weekDay = num == 0 ? 6 : num - 1
	const rangeStart = weekDay * 48
	const rangeEnd = (weekDay + 1) * 48 - 1
	const range = `RANGE:${rangeStart + gaps}:${rangeEnd}`

	return range
}

export const BookingService = {
	async getBooking({ workspaceId, projectId, bookingId }: GetBookingDto) {
		return api.get<GetBookingResponse>(
			`/project/${workspaceId}/${projectId}/API?action=plugin_api&method=load_node_booking_extended&booking_id=${bookingId}&include_point_info=1&plugin_id=53d02367136147b8b5187d109256ce74`,
		)
	},
	async getParallelBooking({
		workspaceId,
		projectId,
		nodeId,
		start,
		end,
		requestId,
		userId,
		exclude,
	}: GetParallelBookingDto) {
		const data = new FormData()

		data.append('node_id', String(nodeId))
		data.append('start_date', String(start))
		data.append('end_date', String(end))
		data.append('request_id', String(requestId))
		data.append('for_user', String(userId))
		data.append('exclude_booking', String(exclude))

		return api.post<GetParallelBookingResponse>(
			`project/${workspaceId}/${projectId}/API?action=plugin_api&plugin_id=53d02367136147b8b5187d109256ce74&method=node_booking_view_v2`,
			data,
		)
	},
	async removeBooking({ workspaceId, projectId, bookingId }: GetBookingDto) {
		return api.get<GetBookingResponse>(
			`/project/${workspaceId}/${projectId}/API?action=plugin_api&plugin_id=53d02367136147b8b5187d109256ce74&method=remove_book&53d02367136147b8b5187d109256ce74book_id=${bookingId}`,
		)
	},
	async getBookingList({
		workspaceId,
		projectId,
		page = 0,
		perPage = 20,
		moment,
		user,
		bookingType,
		placeType,
		place,
		sort,
		direction,
		day,
		start,
		end,
		my,
	}: BookingListDto) {
		const data = new FormData()
		data.append('perpage', String(perPage))
		data.append('page', String(page))
		data.append('sortDirection', String(direction))

		const date = day || formatLocalDateToAPI(new Date())

		// past
		if (!start) {
			if (moment === 'past') {
				data.append('filters[ends][m]', String('LESS'))
				data.append('filters[ends][v1]', String(formatToAPI(date)))
			}
			if (moment === 'current') {
				data.append('filters[starts][m]', String('LESSEQ'))
				data.append('filters[starts][v1]', String(formatToAPI(date)))
				data.append('filters[ends][m]', String('MOREEQORNULL'))
				data.append('filters[ends][v1]', String(formatToAPI(date)))
			}
			if (moment === 'future') {
				data.append('filters[starts][m]', String('MOREEQ'))
				data.append('filters[starts][v1]', String(formatToAPI(date)))
			}
			if (moment === 'constant') {
				data.append('filters[ends][m]', String('ISNULL'))
				data.append('filters[rec]', String(0))
			}
			if (moment === 'recurrent') {
				data.append('filters[rec]', String(1))
			}
			if (moment === 'my') {
				data.append('filters[user]', String(my))
			}
			if (moment === 'common') {
				data.append('filters[rec]', String(0))
				data.append('filters[starts][m]', String('MORE'))
				data.append('filters[starts][v1]', String(formatToAPI(subYears(date, 1000))))
				data.append('filters[ends][m]', String('LESS'))
				data.append('filters[ends][v1]', String(formatToAPI(addYears(date, 1000))))

			}
			if (day || moment === 'current') {
				const num = getDay(date)
				const weekDay = num == 0 ? 6 : num - 1
				const rangeStart = num == 0 ? 288 : weekDay * 48
				const rangeEnd = (weekDay + 1) * 48 - 1

				const range =
					weekDay == 0
						? `330,331,332,333,334,335,${[...Array(rangeEnd + 1).keys()].join(
								',',
						  )}`
						: `RANGE:${rangeStart - 6}:${rangeEnd - 6}`

				data.append('filters[matchgap]', String(range))
			}
		} else {
			let startDate = start
			let endDate = end ?? start
			data.append('filters[starts][m]', String('LESS'))
			data.append('filters[starts][v1]', String(formatToAPI(endOfDay(endDate))))
			data.append('filters[ends][m]', String('MOREEQORNULL'))
			data.append('filters[ends][v1]', String(formatToAPI(startOfDay(startDate))))
	

			const days = eachDayOfInterval({ start, end: end ? end : start }).slice(
				0,
				10,
			)
			// @ts-ignore
			const gapsArr: number[] = days.reduce(
				(acc, day) => acc.concat(getGapString(day)),
				[],
			)

			const gaps = [...new Set(gapsArr)]

			data.append('filters[matchgap]', String(gaps))
		}

		if (user) {
			data.append('filters[user]', String(user))
		}

		if (placeType) {
			data.append('filters[type_uid]', String(placeType))
		}

		if (place) {
			data.append('filters[place]', String(place))
		}

		if (sort !== '') {
			data.append('sortColumn', String(sort))
		}

		// if (bookingType) {
		// 	data.append('filters[user]', String("boris"))
		// }

		return api.post<LayerBookingListResponse>(
			`project/${workspaceId}/${projectId}/API?action=plugin_api&plugin_id=53d02367136147b8b5187d109256ce74&method=booking_list`,
			data,
		)
	},
	async getBookingsForLayer({
		workspaceId,
		projectId,
		layerId,
		start,
		end,
	}: GetBookingsForLayerDto) {
		let query = ''

		if (start && end) {
			const startDate = format(start, "yyyy-MM-dd'T'HH:mm:ss")
			const endDate = format(end, "yyyy-MM-dd'T'HH:mm:ss")

			query = `start=${startDate}&end=${endDate}`
		} else {
			const momentDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")

			query = `moment=${momentDate}`
		}

		return api.get<GetBookingsForLayerResponse>(
			`project/${workspaceId}/${projectId}/API?action=plugin_api&plugin_id=53d02367136147b8b5187d109256ce74&method=get_bookings_for_layer&layer_id=${layerId}&${query}`,
		)
	},
	async getNodes({
		workspaceId,
		projectId,
		page = 0,
		perPage = 20,
		parent,
		name,
		type,
		bookable,
		sort,
		direction,
		categories,
	}: NodeListDto) {
		const data = new FormData()
		data.append('perpage', String(perPage))
		data.append('page', String(page))
		data.append('sortDirection', String(direction))

		if (parent) {
			data.append('filters[parent]', String(parent))
		}

		if (name) {
			data.append('filters[name]', String(name))
		}

		if (type) {
			data.append('filters[type_uid]', String(type))
		}

		if (bookable) {
			data.append(
				'filters[f_53d02367136147b8b5187d109256ce74_bookable]',
				String(bookable),
			)
		}

		if (categories) {
			data.append(
				'filters[f_53d02367136147b8b5187d109256ce74_category]',
				String(categories),
			)
		}

		if (sort && sort !== '') {
			data.append('sortColumn', String(sort))
		}

		return api.post<GetNodeListResponse>(
			`project/${workspaceId}/${projectId}/API?action=node_list`,
			data,
		)
	},
	async getPoint({ workspaceId, projectId, pointId }: GetPointDto) {
		return api.post<PointResponse>(
			`project/${workspaceId}/${projectId}/API?action=plugin_api&plugin_id=53d02367136147b8b5187d109256ce74&method=get_node_for_booking&id=${pointId}`,
		)
	},
}

const getRange = (start, stop) =>
	Array.from({ length: stop - start + 1 }, (_, i) => start + i)

const getGapString = (day) => {
	const num = getDay(day)
	const weekDay = num == 0 ? 6 : num - 1
	const rangeStart = num == 0 ? 288 : weekDay * 48
	const rangeEnd = (weekDay + 1) * 48 - 1

	const gapRange =
		weekDay == 0
			? [330, 331, 332, 333, 334, 335, ...Array(rangeEnd + 1).keys()]
			: getRange(rangeStart - 6, rangeEnd - 6)

	return gapRange
}

interface NodeListDto {
	workspaceId: number
	projectId: number
	page?: number
	perPage?: number
	parent?: string
	name?: string
	type?: string
	categories?: string
	bookable?: 0 | 1
	sort?: string
	direction?: 0 | 1
}

interface GetBookingsForLayerDto {
	workspaceId: number
	projectId: number
	layerId: number
	start?: Date
	end?: Date
}

interface GetPointDto {
	workspaceId: number
	projectId: number
	pointId: number
}

interface BookingListDto {
	workspaceId: number
	projectId: number
	page?: number
	perPage?: number
	user?: string
	placeType?: string
	place?: string
	bookingType?: string
	moment?: MomentType
	sort?: string
	direction?: 0 | 1
	day?: Date
	start?: Date
	end?: Date
	my?: string
}

export interface GetNodeListResponse extends ApiResponseType {
	items: NodeElement[]
	total: number
}

export interface NodeElement {
	id: string
	type_uid: string
	parent: string
	name: string
	sort: string
	x: string
	y: string
	type_name: string
}

export interface GetParallelBookingDto {
	workspaceId: number
	projectId: number
	nodeId: number
	start: string
	end: string
	requestId?: number
	userId: number
	exclude?: number
}

export interface GetBookingDto {
	workspaceId: number
	projectId: number
	bookingId: number
}

export interface BookingListResponse extends ApiResponseType {
	items: BookingItem[]
	total: number
}

export interface LayerBookingListResponse extends ApiResponseType {
	items: BookingItem[]
	total: number
}

export interface GetBookingsForLayerResponse extends ApiResponseType {
	bookings: LayerBookingItem[]
}

export interface PointResponse extends ApiResponseType {
	node_info: NodeInfo
}

export interface LayerBookingItem {
	key: string
	display: string
	user_id: string
	user_uts: string
	booking_id: string
	point_id: string
	recurrence: string
	start: string
	end: string
	gap: number[] | null
}

export interface BookingItem {
	id: string
	start: string
	end: string
	rec: string
	bookable_id: string
	booking_type: string
	name: string
	parent_layer_id: string
	is_master: string
	user: string
	user_id: number | string
	gap: string
	location: string[]
}

export interface GetBookingResponse extends ApiResponseType {
	booking: BookingData
	node_info: NodeInfo
}

export interface BookingData {
	gap: number[]
	workspace: number
	project: number
	metablock: number
	plugin_id: string
	id: number
	user_id: number
	user_name: string
	node_id: number
	is_master: boolean
	booking_type: number
	rec: boolean
	start: string
	end: string
	childs: string[][]
}

export type NodeInfo = {
	id: number
	parent: number
	parent_name: string[]
	type_uid: string
	name: string
	info?: any
	sort: number
	x: number
	y: number
	class_version: string
}

export interface GetParallelBookingResponse extends ApiResponseType {
	node_booking_view_v2: BookingView
}

export interface BookingView {
	items: ParallelBookingItem[]
	start: string
	end: string
	resource: number
	user: string
	rec: boolean
}

export interface ParallelBookingItem {
	reason: string
	point_id: number
	point_name: string
	point_type_name: string
	id: number
	rec: boolean
	start: string
	end: string
	user: number
	user_name: string
	gap: number[]
}
