import React, { useEffect, useRef, useState } from 'react'
import { useMapStore } from '@/stores/mapStore'
import styled, { css } from 'styled-components'

const TooltipWrapper = styled.div<{ $visible: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	will-change: transform;
	pointer-events: none;
	opacity: 0;
	visibility: hidden;
	z-index: 121;
	${({ $visible }) =>
		$visible &&
		css`
			opacity: 1;
			visibility: visible;
		`}
`

const Wrapper = styled.div`
	background: #000000;
	border-radius: 10px;
	padding: 5px 10px;
	font-family: 'Roboto';
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #ffffff;

	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -10px;
		border-width: 10px;
		border-style: solid;
		border-color: black transparent transparent transparent;
	}
`

const CalendarTooltip = () => {
	const ttRef = useRef(null)
	const tooltip = useMapStore((state) => state.tooltip)

	const [visible, setVisible] = useState(false)
	const [point, setPoint] = useState({ x: 0, y: 0 })

	const onMouseMove = (ev: MouseEvent) => {
		const calendar = document.getElementById('book-form-calendar')

		const cal = calendar?.getBoundingClientRect() || { left: 0, top: 0 }

		const ttPoint = {
			x: ev.clientX - cal.left,
			y: ev.clientY - cal.top - 50,
		}

		setPoint(ttPoint)
	}

	useEffect(() => {
		document.addEventListener('mousemove', onMouseMove)

		return function () {
			document.removeEventListener('mousemove', onMouseMove)
		}
	}, [])

	// @ts-ignore
	let timer

	useEffect(() => {
		if (!tooltip) {
			clearTimeout(timer)
			setVisible(false)
			return
		} else {
			setVisible(false)
		}

		timer = setTimeout(function () {
			setVisible(true)
		}, 0)

		return () => {
			clearTimeout(timer)
		}
	}, [tooltip])

	return (
		<TooltipWrapper
			ref={ttRef}
			$visible={visible}
			style={{ transform: `translateX(${point.x}px) translateY(${point.y}px)` }}
		>
			<Wrapper>{tooltip}</Wrapper>
		</TooltipWrapper>
	)
}

export default CalendarTooltip
