import axios from 'axios'

const axiosInstance = axios.create({
	validateStatus: (status) => status === 200,
	responseType: 'json',
	headers: {
		'Cache-Control': 'max-age=900',
	},
})

export const fetchJSON = async <T>(
	url: string,
	error = 'Can not load JSON from remote server',
) => {
	const response = await axiosInstance.get<T>(url)

	if (typeof response.data !== 'object') {
		throw new Error(error)
	}

	return response.data
}
