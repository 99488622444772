import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import { BookingService } from '../services/booking.service'
import {
  generateBookingGridDates,
  extractGaps,
  convertGapToSlot,
  formatToISOTimezone,
  convertGapToSlotWeekly
} from '@/utils/helpers/dates.helpers'
import { BookingItem, BookingTypeEnum } from '@/api/bookings'
import { addYears, eachWeekOfInterval, parseISO } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { StatusResponseEnum } from '../types'
import { redirectSAML } from '..'
import { useUserStore } from '@/stores/userStore'

const getBookingType = (item: BookingItem) => {
  return item.rec
    ? BookingTypeEnum.Recurent
    : item.end
    ? BookingTypeEnum.Regular
    : BookingTypeEnum.Constant
}

const extractSlots = (data) => {
  if (!data || !data.booking) return []

  const type = getBookingType(data.booking)

  if (type === BookingTypeEnum.Regular) {
    return generateBookingGridDates(data)
  }

  if (type === BookingTypeEnum.Recurent) {
    const gapSlots = extractGaps(data.booking.gap)

    return gapSlots.reduce((acc, gap) => {
      // const slot = convertGapToSlotWeekly(gap)

      const weeks = eachWeekOfInterval(
        {
          start: formatToISOTimezone(data.booking.start),
          end: formatToISOTimezone(data.booking.end)
        },
        { weekStartsOn: 1 }
      )

      const slots = weeks.map((currWeek) => {
        const slot = convertGapToSlotWeekly(gap, currWeek)

        return {
          type,
          user: data.booking.user_id,
          display: data.booking.user_name,
          start: formatToISOTimezone(slot.start),
          end: formatToISOTimezone(slot.end)
        }
      })

      return acc.concat(slots)
    }, [])
  }

  return [
    {
      type,
      user: data.booking.user_id,
      display: data.booking.user_name,
      start: formatToISOTimezone(data.booking.start),
      end: addYears(formatToISOTimezone(data.booking.start), 1000)
    }
  ]
}

export const useBooking = (id?: number | string | null) => {
  const { workspaceId, projectId } = useProject()
  const { enqueueToast } = useToast()
  const navigate = useNavigate()
  const authRef = useUserStore((state) => state.authRef)

  const bookingId = Number(id)

  const { data, isLoading, refetch } = useQuery(
    ['booking', workspaceId, projectId, id],
    () => BookingService.getBooking({ workspaceId, projectId, bookingId }),
    {
      enabled: !!workspaceId && !!projectId && !!bookingId,
      select: ({ data }) => ({
        status: data.status,
        booking: data.booking,
        node: data.node_info,
        slots: extractSlots(data)
      }),
      onError: () => {
        enqueueToast(
          {
            title: 'Ошибка!',
            message: 'Не удалось загрузить данные о бронировании'
          },
          { variant: 'error' }
        )
      }
    }
  )

  useEffect(() => {
    if (data && data.status === StatusResponseEnum.NotAuthorized) {
      if (authRef) {
        redirectSAML(authRef, workspaceId, projectId)
      } else {
        navigate(`/login?workspace_id=${workspaceId}&project_id=${projectId}`)
      }
    }
  }, [isLoading])

  return { data, isLoading, refetch }
}
