import { useQuery } from 'react-query'
import { useToast } from '@/components/shared/toast/useToast'
import { useProject } from '@/hooks/useProject'
import { ReportsService } from '../services/report.service'

export const useReport = ({ reportId, params }: UseReportDTO) => {
	const { workspaceId, projectId } = useProject()
	const { enqueueToast } = useToast()

	const { data, isLoading, refetch } = useQuery(
		['report', workspaceId, projectId, reportId, params],
		() =>
			ReportsService.getReport({
				workspaceId,
				projectId,
				reportId,
				params,
			}),
		{
			enabled: !!workspaceId && !!reportId,
			select: ({ data }) => data,
			onError: ({ message }) => {
				enqueueToast({ title: 'Ошибка!', message }, { variant: 'error' })
			},
		},
	)

	return {
		data,
		isLoading,
		refetch,
	}
}

type UseReportDTO = {
	reportId: string
	params: any
}
