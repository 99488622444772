import Close from '@/components/Close'
import Grid from '@/components/Grid'
import Modal, { StaticModal } from '@/components/Modal/Modal'
import media from '@/ui/media'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTree } from '@/api/hooks/useTree'
import { useLayerInfo } from '@/api/hooks/useLayerInfo'
import { useMetadata } from '@/api/hooks/useMetadata'
import { linkLayerInfoProperties } from '../Layers/LayerInfoModal'
import { ErrorBoundary } from 'react-error-boundary'
import Toolbar from '@/components/Toolbar/Toolbar'
import { SelectInput } from '@/ui/components/Field/Select'
import { debounce } from 'lodash'
import { useProjectStore } from '@/stores/projectStore'
import { Input } from '@/ui/components/Field/Input'
import { useNodes } from '@/api/hooks/useNodes'
import useSorting from '@/hooks/useSorting'
import { TableCell } from '@/components/Employee/Employees'
import ElementsListItem from '@/components/Elements/ElementsListItem'
import Pagination from '@/ui/components/Pagination/Pagination'
import useResponsive from '@/hooks/useResponsive'
import EmptySeatsElement from './EmptySeatsElement'
import { ReportButton } from './ReportView'
import { translate } from '@/i18n'
import { useIntl } from 'react-intl'

type ReportModalProps = {
	report: string | null
	item: {
		name: string
		key: string
	}
	close: () => void
}

const EmptySeatsReportModal: React.FC<ReportModalProps> = ({
	report,
	item,
	close: onClose,
}) => {
	const [currentPage, setCurrentPage] = useState<number>(1)
	const intl = useIntl()
	const nodes = useProjectStore((state) => state.nodes)
	const [parent, setParent] = useState<string>('')
	const [type, setType] = useState<string>('')
	const [name, setName] = useState<string>('')

	const { sort, direction, handleSort } = useSorting()
	const { data, isLoading } = useNodes({
		page: currentPage - 1,
		name,
		parent,
		type,
		sort,
		direction,
	})
	const { metadata } = useMetadata()
	const { isDesktop } = useResponsive()

	const handleNameChange = (e) => setName(e?.target?.value)
	const handleParentChange = (e) => setParent(e?.target?.value)
	const handleTypeChange = (e) => setType(e?.target?.value)

	const debouncedNameResponse = useMemo(() => {
		return debounce(handleNameChange, 500)
	}, [])

	useEffect(() => {
		return () => debouncedNameResponse.cancel()
	}, [])

	return (
		<StaticModal onClose={onClose}>
			<Header>
				<Title>
					{translate('report-template', {
						name: intl.formatMessage({ id: item.key }),
					})}
				</Title>
				<ExportWrapper>
					<ReportButton>PDF</ReportButton>
					<ReportButton>CSV</ReportButton>
					<Close color="#000" onClick={onClose} />
				</ExportWrapper>
			</Header>

			<Toolbar>
				<Toolbar.Item xs={6} md={4}>
					<Toolbar.BlackLabel>Дата и время начала</Toolbar.BlackLabel>
					<Input $fullWidth placeholder="Дата и время начала" />
				</Toolbar.Item>
				<Toolbar.Item xs={6} md={4}>
					<Toolbar.BlackLabel>Расположение</Toolbar.BlackLabel>
					<SelectInput
						$fullWidth
						placeholder="Выберите расположение"
						value={parent}
						onChange={handleParentChange}
					>
						<option value="">Все уровни</option>
						{nodes.map((node) => (
							<option key={node.id} value={node.id}>
								{node.name}
							</option>
						))}
					</SelectInput>
				</Toolbar.Item>
				<Toolbar.Item xs={6} md={4}>
					<Toolbar.BlackLabel>Тип объектов</Toolbar.BlackLabel>
					<SelectInput
						$fullWidth
						placeholder="Выберите тип места"
						value={type}
						onChange={handleTypeChange}
					>
						<option value="">Все</option>
						{metadata?.nodes?.map((node) => (
							<option key={node.uid} value={node.uid}>
								{node.name}
							</option>
						))}
					</SelectInput>
				</Toolbar.Item>
			</Toolbar>

			<Grid>
				{isDesktop && (
					<Grid.RowHeader $cols="2fr 1fr 1fr">
						<TableCell
							onClick={handleSort.bind(null, 'name')}
							$active={sort === 'name'}
							$direction={direction}
						>
							Наименование
						</TableCell>
						<TableCell
							onClick={handleSort.bind(null, 'parent')}
							$active={sort === 'parent'}
							$direction={direction}
						>
							Расположение
						</TableCell>
						<TableCell
							onClick={handleSort.bind(null, 'type_name')}
							$active={sort === 'type_name'}
							$direction={direction}
						>
							Тип
						</TableCell>
					</Grid.RowHeader>
				)}

				{data?.items.map((spot) => (
					<EmptySeatsElement key={spot.id} item={spot} />
				))}
			</Grid>

			<Pagination
				inverse
				currentPage={currentPage}
				total={data?.total || 0}
				handlePageChange={setCurrentPage}
			/>
		</StaticModal>
	)
}

export default EmptySeatsReportModal

const ReportItem = ({ item }) => {
	const { layers } = useTree()
	const { data } = useLayerInfo(item.id)
	const { metadata } = useMetadata()

	const currentNode = useMemo(() => {
		if (data && metadata && metadata.layers) {
			return metadata.layers[data.info.type_uid]
		}
		return null
	}, [data, metadata])

	const parent = layers?.nodes?.find((p) => p.id == item.parent)?.name || ''
	const props = linkLayerInfoProperties(
		currentNode?.plugin_data,
		data?.info?.plugin_data,
	)

	const properties = {
		name: props.find((prop) => prop.name == 'Компания')?.value || '',
		area: props.find((prop) => prop.name == 'Площадь')?.value || '',
		count: props.find((prop) => prop.name == 'Кол-во мест')?.value || '',
	}

	return (
		<Grid.Row $cols="repeat(3, 1fr)">
			<Grid.Item>{item.name}</Grid.Item>
			<Grid.Item>{parent}</Grid.Item>
			<Grid.Item>{properties.name}</Grid.Item>
		</Grid.Row>
	)
}

const NotFound = styled.div`
	width: 100%;
	text-align: center;
	padding: 12px 0;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${media.lg`
      flex-direction: column;
      align-items: flex-start;
  `}
`

const ExportWrapper = styled.div`
	display: flex;
	align-items: center;

	* {
		&:not(:last-child) {
			margin-right: 0.8rem;
		}
	}

	${media.lg`
      margin-top: 20px;
  `}
`

const Title = styled.div`
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: #000000;
`
