import { useNodes } from '@/api/hooks/useNodes'
import Close from '@/components/Close'
import { Place } from '@/components/Elements/ElementsListItem'
import Grid from '@/components/Grid'
import Modal, { StaticModal } from '@/components/Modal/Modal'
import Pagination from '@/ui/components/Pagination/Pagination'
import media from '@/ui/media'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTree } from '@/api/hooks/useTree'
import { useLayerInfo } from '@/api/hooks/useLayerInfo'
import { useMetadata } from '@/api/hooks/useMetadata'
import { linkLayerInfoProperties } from '../Layers/LayerInfoModal'
import { ErrorBoundary } from 'react-error-boundary'
import { ReportButton } from './ReportView'
import { translate } from '@/i18n'
import { useIntl } from 'react-intl'

type ReportModalProps = {
	report: string | null
	item: {
		name: string
		key: string
	}
	close: () => void
}

const SquareReportModal: React.FC<ReportModalProps> = ({
	report,
	item,
	close: onClose,
}) => {
	const [currentPage, setCurrentPage] = useState<number>(1)
	const { layers } = useTree()
	const intl = useIntl()

	return (
		<StaticModal onClose={onClose}>
			<Header>
				<Title>
					{translate('report-template', {
						name: intl.formatMessage({ id: item.key }),
					})}
				</Title>
				<ExportWrapper>
					{/* <ReportButton>PDF</ReportButton>
                    <ReportButton>CSV</ReportButton> */}
					<Close color="#000" onClick={onClose} />
				</ExportWrapper>
			</Header>

			<Grid>
				<Grid.RowHeader $cols="repeat(5, 1fr)">
					<Grid.Item>{translate('report-area-cabinet-name')}</Grid.Item>
					<Grid.Item>{translate('report-area-parent-name')}</Grid.Item>
					<Grid.Item>{translate('report-area-arender-name')}</Grid.Item>
					<Grid.Item>{translate('report-area-square')}</Grid.Item>
					<Grid.Item>{translate('report-area-places')}</Grid.Item>
				</Grid.RowHeader>

				{layers?.nodes.length ? (
					layers?.nodes.map((spot) => (
						<ErrorBoundary fallback={<React.Fragment key={spot.id} />}>
							<ReportItem key={spot.id} item={spot} />
						</ErrorBoundary>
					))
				) : (
					<NotFound>{translate('no-results')}</NotFound>
				)}
			</Grid>

			{/* <Pagination inverse currentPage={currentPage} total={data?.total || 0} handlePageChange={setCurrentPage} /> */}
		</StaticModal>
	)
}

export default SquareReportModal

const ReportItem = ({ item }) => {
	const { layers } = useTree()
	const { data } = useLayerInfo(item.id)
	const { metadata } = useMetadata()

	const currentNode = useMemo(() => {
		if (data && metadata && metadata.layers) {
			return metadata.layers[data.info.type_uid]
		}
		return null
	}, [data, metadata])

	const parent = layers?.nodes?.find((p) => p.id == item.parent)?.name || ''
	const props = linkLayerInfoProperties(
		currentNode?.plugin_data,
		data?.info?.plugin_data,
	)

	const properties = {
		name: props.find((prop) => prop.name == 'Компания')?.value || '',
		area: props.find((prop) => prop.name == 'Площадь')?.value || '',
		count: props.find((prop) => prop.name == 'Кол-во мест')?.value || '',
	}

	return (
		<Grid.Row $cols="repeat(5, 1fr)">
			<Grid.Item>{item.name}</Grid.Item>
			<Grid.Item>{parent}</Grid.Item>
			<Grid.Item>{properties.name}</Grid.Item>
			<Grid.Item>{properties.area}</Grid.Item>
			<Grid.Item>{properties.count}</Grid.Item>
		</Grid.Row>
	)
}

const NotFound = styled.div`
	width: 100%;
	text-align: center;
	padding: 12px 0;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${media.lg`
      flex-direction: column;
      align-items: flex-start;
  `}
`

const ExportWrapper = styled.div`
	display: flex;
	align-items: center;

	* {
		&:not(:last-child) {
			margin-right: 0.8rem;
		}
	}

	${media.lg`
      margin-top: 20px;
  `}
`

const Title = styled.div`
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: #000000;
`
