import { FormLabel } from '@/ui/components/Form/FormLabel'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import WeeklyInterval from './WeeklyInterval'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import {
  addMinutes,
  eachWeekOfInterval,
  getWeek,
  isValid,
  setDay,
  setWeek
} from 'date-fns'
import useResponsive from '@/hooks/useResponsive'
import { uniqArray } from '@/utils/helpers/array.helpers'
import { startOfWeek } from 'date-fns'
import { endOfWeek } from 'date-fns'
import { useBookingStore } from '@/stores/bookingStore'
import { translate } from '@/i18n'
import { times } from 'lodash'

const weekDays = [
  {
    label: 'monday',
    day: 1
  },
  {
    label: 'tuesday',
    day: 2
  },
  {
    label: 'wensday',
    day: 3
  },
  {
    label: 'thursday',
    day: 4
  },
  {
    label: 'friday',
    day: 5
  },
  {
    label: 'saturday',
    day: 6
  },
  {
    label: 'sunday',
    day: 0
  }
]

export const generateWeeklyDates = (start, end, interval) => {
  if (!interval || !interval.start || !interval.end) {
    return []
  }

  // const uniqueDays = uniqArray(days)
  const weeks = eachWeekOfInterval(
    { start: new Date(start), end: new Date(end) },
    { weekStartsOn: 1 }
  )

  return weeks.map((week) => {
    const weekNum = getWeek(week, { weekStartsOn: 1 })
    return {
      start: setWeek(new Date(interval.start), weekNum, { weekStartsOn: 1 }),
      end: setWeek(new Date(interval.end), weekNum, { weekStartsOn: 1 })
    }
  })
}

const WeeklySelector = () => {
  const { setFieldValue, values } = useFormikContext<any>()
  const { isMobile } = useResponsive()
  const week = useBookingStore((state) => state.week)
  const days = values.days
  const interval = values.weekly
  const recurrent = values.recurrent

  const weekStart = startOfWeek(week, { weekStartsOn: 1 })
  const weekEnd = endOfWeek(week, { weekStartsOn: 1 })
  const startBook =
    values.start || weekStart || startOfWeek(new Date(), { weekStartsOn: 1 })
  const endBook =
    values.end || weekEnd || endOfWeek(new Date(), { weekStartsOn: 1 })

  const handleDaySelect = (day: number | null = null) => {
    const isIncluded = days.includes(day)
    let newDays: any[] = []

    if (isIncluded) {
      newDays = days.filter((d) => d != day)
    } else {
      newDays = [...days, day].sort((a, b) => a - b)
    }

    const weeks = eachWeekOfInterval(
      { start: startBook, end: endBook },
      { weekStartsOn: 1 }
    )
    const { start, end } = values.recurrent
    const timeSlots = weeks.reduce((acc, currWeek) => {
      const weekSlots: any[] = []
      newDays.forEach((d) => {
        weekSlots.push({
          start: setDay(
            setWeek(start, getWeek(currWeek), { weekStartsOn: 1 }),
            d
          ),
          end: setDay(setWeek(end, getWeek(currWeek), { weekStartsOn: 1 }), d)
        })
      })
      return acc.concat(weekSlots)
    }, [] as any)

    setFieldValue(
      'dates',
      timeSlots.filter((slot) => isValid(slot.start) && isValid(slot.end))
    )
    setFieldValue('days', newDays)
  }

  useEffect(() => {
    if (
      !isValid(new Date(recurrent.start)) ||
      !isValid(new Date(recurrent.end))
    )
      return
    handleDaySelect()
  }, [recurrent])

  useEffect(() => {
    const timeSlots = interval.map(({ start, end }) => ({
      start: addMinutes(start, -180),
      end: addMinutes(end, -180)
    }))
    // const slots = generateWeeklyDates(days, interval)
    //
    // const weeks = eachWeekOfInterval(
    //   { start: startBook, end: endBook },
    //   { weekStartsOn: 1 }
    // )
    // const timeSlots = weeks.reduce((acc, currWeek) => {
    //   const weekSlots: any[] = []
    //   slots.forEach((slot) => {
    //     weekSlots.push({
    //       start: setWeek(slot.start, getWeek(currWeek), { weekStartsOn: 1 }),
    //       end: setWeek(slot.end, getWeek(currWeek), { weekStartsOn: 1 })
    //     })
    //   })
    //   return acc.concat(weekSlots)
    // }, [] as any)

    setFieldValue(
      'dates',
      timeSlots.filter((slot) => isValid(slot.start) && isValid(slot.end))
    )
  }, [interval])

  if (!isMobile) return null

  return (
    <Wrapper>
      <FormLabel>{translate('week-days')}</FormLabel>
      <DaysWrapper>
        {weekDays.map((day) => (
          <WeeklyDay
            key={day.day}
            onChange={handleDaySelect}
            day={day.day}
            days={days}
          >
            {translate(day.label)}
          </WeeklyDay>
        ))}
      </DaysWrapper>
      <TimeInputs>
        <WeeklyInterval />
      </TimeInputs>
    </Wrapper>
  )
}

type WeeklyDayProps = {
  onChange: (day: number) => void
  day: number
  days: number[]
}

const WeeklyDay: React.FC<WeeklyDayProps> = ({
  children,
  onChange,
  day,
  days
}) => {
  const isActive = days.indexOf(day) + 1 > 0
  return (
    <Day onClick={onChange.bind(null, day)} $active={isActive}>
      {children}
    </Day>
  )
}

export default WeeklySelector

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DaysWrapper = styled.div`
  display: flex;
`

const Day = styled.div<{ $active?: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  border-radius: 8px;
  flex-shrink: 0;
  cursor: pointer;

  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #838d96;

  //&:hover {
  //  color: #ffffff;
  //  background: #09a0af;
  //}

  ${({ $active }) =>
    $active &&
    css`
      color: #ffffff;
      background: #09a0af;
    `}

  &:not(:last-child) {
    margin-right: 12px;
  }
`

const TimeInputs = styled.div`
  display: flex;
`
