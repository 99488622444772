import React, { useState } from 'react'
import styled from 'styled-components'
import ReportView from './ReportView'
import InfoIcon from '@/components/icons/InfoIcon'
import { IReport } from '@/api/services/report.service'
import { translate } from '@/i18n'
import BomjReportView from '@/components/layout/Sidebar/Reports/reports/bomj-report/BomjReport'
import ModalService from '@/components/shared/modal/ModalService'
import ReportModal from '@/components/layout/Sidebar/Reports/ReportModal'
import SquareReportModal from '@/components/layout/Sidebar/Reports/SquareReports'
import EmptySeatsReportModal from '@/components/layout/Sidebar/Reports/EmptySeats'

type ReportsItemProps = {
	report: IReport
}

const reportTranslations = {
	'free-places-v1': 'Свободные места за период',
}

const translateName = (name) => {
	const reportName = reportTranslations[name]

	return reportName ? reportName : name
}

const Report: React.FC<ReportsItemProps> = ({ report }) => {
	const handleReportOpen = (key: string) => {
		const props = { report: { ...report, name: report.name }, open: true }
		if (key === 'free-places-v1' || key === 'non-free-places-v1') {
			ModalService.open(ReportView, props)
		}

		if (key === 'no-place-users') {
			ModalService.open(BomjReportView, props)
		}
	}

	return (
		<>
			<ReportWrapper>
				<ReportName>{translate(report.name)}</ReportName>
				<ReportControls>
					{/* <ReportControlIconButton onClick={() => setReport('parking')}>
                        <ExportIcon />
                    </ReportControlIconButton> */}
					<ReportControlButton
						onClick={handleReportOpen.bind(null, report.name)}
					>
						<InfoIcon color="#666" />
					</ReportControlButton>
				</ReportControls>
			</ReportWrapper>
		</>
	)
}

export default Report

const ReportWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
`

const ReportName = styled.div`
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 1.6rem;
	margin-right: 1.6rem;
	color: #666;
`

const ReportControls = styled.div`
	display: flex;
	align-items: center;
`

const ReportControlButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	background: transparent;
	cursor: pointer;

	&:not(:last-child) {
		margin-right: 0.8rem;
	}
`

const ReportControlIconButton = styled(ReportControlButton)`
	width: 30px;
	height: 30px;
	background: #fff;
	border-radius: 50%;
`
