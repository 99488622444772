import ReactQueryProvider from '@/lib/react-query'
import en from 'date-fns/locale/en-GB'
import ru from 'date-fns/locale/ru'
import { useLayoutEffect } from 'react'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-datepicker/dist/react-datepicker.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-tooltip/dist/react-tooltip.css'
import { ThemeProvider } from 'styled-components'
import ConfirmDialog from './components/ConfirmDialog'
import { GlobalStyles } from './components/layout/GlobalStyles'
import { Reset } from './components/layout/Reset'
import ToastProvider from './components/shared/toast/ToastProvider'
import AppContainer from './containers/AppContainer'
import { LocaleProvider, LOCALES } from './i18n'
import blueTheme from './ui/blueTheme'
import { BrowserRouter as Router } from 'react-router-dom'
import '@/assets/styles/index.css'
import { VersionLabel } from '@/components/VersionLabel'
import ModalRoot from '@/components/shared/modal/ModalRoot'

const selectedLocale = LOCALES.RUSSIAN
export const dateFnsLocale = selectedLocale == LOCALES.ENGLISH ? en : ru

const App = () => {
	useLayoutEffect(() => {
		const appHeight = () => {
			document.documentElement.style.setProperty(
				'--app-height',
				`${window.innerHeight}px`,
			)
		}
		appHeight()

		window.addEventListener('resize', appHeight)

		return () => window.removeEventListener('resize', appHeight)
	}, [])

	return (
		<Router>
			<LocaleProvider locale={selectedLocale}>
				<ThemeProvider theme={blueTheme}>
					<ToastProvider>
						<ReactQueryProvider>
							{/* Reset styling */}
							<Reset />
							<GlobalStyles />

							{/* Main Application */}
							<AppContainer />
							<ConfirmDialog />
							<ModalRoot />
							<VersionLabel />
						</ReactQueryProvider>
					</ToastProvider>
				</ThemeProvider>
			</LocaleProvider>
		</Router>
	)
}

export const loadTimestamp = Date.now()
export default App
